import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import { Container, Row } from "react-bootstrap";
import StoreHeader from "./StorefrontHeader";
import StorefrontSearch from "./StorefrontSearch";
import "./addMember.css";
import "./Storefront.css";
import MemberSignupForm from "./MemberSignupForm";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

function StoreFront() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [clerk, setClerk] = useState("");
  const [store, setStore] = useState("");
  //LOGGED IN USER
  const { id } = useParams();
  const [managerDetails, setManagerDetails] = useState("");
  const getManager = () => {
    axiosPrivate
      .get("/getSingleManager", { params: { id: id } })
      .then((response) => {
        const result = response.data;
        setManagerDetails(result[0]);
        setClerk(result[0].first_name + " " + result[0].last_name);
        setStore(result[0].preferredStore);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const [memberList, setMemberList] = useState("");
  const getAllMembers = () => {
    axiosPrivate
      .get("/getMembers")
      .then((response) => {
        setMemberList(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  useEffect(() => {
    getManager();
    getAllMembers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="storefrontMain">
      <Container fluid="md">
        <StoreHeader loggedInManager={managerDetails} />
        <StorefrontSearch
          memberList={memberList}
          getAllMembers={getAllMembers}
        />
        <Row className="newMember">
          <h1 className="storefront-section-title">Sign Up Form</h1>
          <p className="underline"></p>
          {/* REGISTER NEW CUSTOMER */}
          <MemberSignupForm
            getAllMembers={getAllMembers}
            clerk={clerk}
            store={store}
          />
        </Row>
      </Container>
    </div>
  );
}

export default StoreFront;
