import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import "../header.css";
import logo from "../images/EAC_TopBannerLogo_V01.jpg";
import { Icon, Modal, Button } from "semantic-ui-react";
import React, { useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import axios from "../api/axios";

function ProfileHeader(props) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const form = useRef();
  const loggedInMember = props.loggedInMember;
  const greetingName = loggedInMember.first_name;
  const [newPhone, setNewPhone] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const auth = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  //CHANGE USERNAME
  const ChangeUsername = (id) => {
    axiosPrivate
      .put("/changeUsername", {
        username: newUsername,
        id: id,
      })
      .then(() => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeEmail = (id) => {
    axiosPrivate
      .put("/changeEmail", {
        email: newEmail,
        id: id,
      })
      .then(() => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangePhone = (id) => {
    axiosPrivate
      .put("/changePhone", {
        phone: newPhone,
        id: id,
      })
      .then(() => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeAddress = (id) => {
    axiosPrivate
      .put("/changeAddress", {
        address: newAddress,
        id: id,
      })
      .then(() => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangePassword = (id) => {
    axiosPrivate
      .put("/changePassword", {
        password: newPassword,
        id: id,
      })
      .then(() => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //SEND ISSUE EMAIL
  const sendIssueEmail = () => {
    emailjs
      .sendForm(
        "service_640rs57",
        "template_95cld3x",
        form.current,
        "user_QGlVs4Qz8yzIHPSfomOw6"
      )
      .then(
        (result) => {
          alert("Your report has been submited.");
        },
        (error) => {}
      );
  };

  const handleReportSubmit = (e) => {
    e.preventDefault();
    sendIssueEmail();
    e.target.reset();
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    ChangeEmail(loggedInMember.id);
    e.target.reset();
    alert("Your email address was changed.");
  };

  const handleUsernameSubmit = (e) => {
    e.preventDefault();
    ChangeUsername(loggedInMember.id);
    e.target.reset();
    alert("Your username was changed.");
  };

  const handlePhoneSubmit = (e) => {
    e.preventDefault();
    ChangePhone(loggedInMember.id);
    e.target.reset();
    alert("Your phone number was changed.");
  };

  const handleAddressSubmit = (e) => {
    e.preventDefault();
    ChangeAddress(loggedInMember.id);
    e.target.reset();
    alert("Your address was changed.");
  };

  const handleNewPasswordSubmit = (e) => {
    e.preventDefault();
    ChangePassword(loggedInMember.id);
    e.target.reset();
    alert("Your password was changed.");
  };

  const handleLogout = () => {
    axios.get("/logout");
    auth.logout();
    navigate("/");
  };

  return (
    <Row className="header-banner">
      <Col className="header-col-left"></Col>
      <Col className="header-logo-container">
        <img
          className="header-logo"
          alt="Executive Access Logo"
          src={logo}
        ></img>
      </Col>
      <Col className="header-drpdwn-container">
        <div className="dropdown">
          <button className="dropbtn">
            Hello, {greetingName}!
            <Icon name="caret down"></Icon>
          </button>
          <div className="dropdown-content">
            <Modal
              className="report-issue-modal"
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              style={{
                height: "65%",
                width: "500px",
                position: "fixed",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "0px",
              }}
              trigger={
                <Button className="btn-drpdwn">
                  Report an Issue{" "}
                  <Icon
                    name="warning sign"
                    style={{ marginLeft: "5px" }}
                  ></Icon>
                </Button>
              }
            >
              <Modal.Actions>
                <Button icon="x" onClick={() => setOpen(false)} />
              </Modal.Actions>
              <Modal.Header>
                Report an Issue
                <p className="modal-header-underline"></p>
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <form
                    className="report-issue-form"
                    ref={form}
                    onSubmit={handleReportSubmit}
                  >
                    <input
                      required
                      type="text"
                      placeholder="Title"
                      name="title"
                    />
                    <input
                      required
                      type="text"
                      placeholder="Name"
                      name="issuer name"
                    />
                    <textarea
                      required
                      type="text"
                      placeholder="Detailed Description of Issue"
                      name="description"
                    ></textarea>
                    <input type="submit" value="Submit Issue" />
                  </form>
                </Modal.Description>
              </Modal.Content>
            </Modal>
            <Modal
              className="edit-profile-modal"
              onClose={() => setOpen2(false)}
              onOpen={() => setOpen2(true)}
              open={open2}
              style={{
                height: "80%",
                position: "fixed",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "0px",
                textAlign: "center",
              }}
              trigger={
                <Button className="btn-drpdwn">
                  Edit Profile{" "}
                  <Icon name="edit" style={{ marginLeft: "5px" }}></Icon>
                </Button>
              }
            >
              <Modal.Actions>
                <Button icon="x" onClick={() => setOpen2(false)} />
              </Modal.Actions>
              <Modal.Header className="edit-modal-header">
                Edit Profile
                <p className="modal-header-underline"></p>
              </Modal.Header>
              <Modal.Content style={{ float: "center" }}>
                <form ref={form} onSubmit={handleEmailSubmit}>
                  <div>
                    <label
                      htmlFor="edit_email"
                      className="edit-modal-field-label"
                    >
                      Email
                    </label>
                    <input
                      required
                      onChange={(y) => {
                        setNewEmail(y.target.value);
                      }}
                      type="email"
                      name="edit_email"
                      placeholder="Enter new email adddress"
                      className="edit-profile-modal-input"
                    ></input>
                    <input
                      type="submit"
                      className="edit-modal-field-submit"
                      value="Change Email"
                    ></input>
                  </div>
                </form>
                <form ref={form} onSubmit={handleUsernameSubmit}>
                  <div>
                    <label
                      htmlFor="edit_username"
                      className="edit-modal-field-label"
                    >
                      Username
                    </label>
                    <input
                      required
                      onChange={(l) => {
                        setNewUsername(l.target.value);
                      }}
                      type="text"
                      name="edit_username"
                      placeholder="Enter new username"
                      className="edit-profile-modal-input"
                    ></input>
                    <input
                      className="edit-modal-field-submit"
                      type="submit"
                      value="Change Username"
                    ></input>
                  </div>
                </form>
                <form ref={form} onSubmit={handlePhoneSubmit}>
                  <div>
                    <label
                      htmlFor="edit_phone"
                      className="edit-modal-field-label"
                    >
                      Phone
                    </label>
                    <input
                      required
                      type="tel"
                      pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                      onChange={(u) => {
                        setNewPhone(u.target.value);
                      }}
                      placeholder="Enter new phone number"
                      className="edit-profile-modal-input"
                      name="edit_phone"
                    ></input>
                    <input
                      className="edit-modal-field-submit"
                      type="submit"
                      value="Change Phone"
                    ></input>
                  </div>
                </form>
                <form ref={form} onSubmit={handleAddressSubmit}>
                  <div>
                    <label
                      htmlFor="edit_address"
                      className="edit-modal-field-label"
                    >
                      Address
                    </label>
                    <input
                      required
                      type="text"
                      onChange={(i) => {
                        setNewAddress(i.target.value);
                      }}
                      placeholder="Enter new address"
                      className="edit-profile-modal-input"
                      name="edit_address"
                    ></input>
                    <input
                      className="edit-modal-field-submit"
                      type="submit"
                      value="Change Address"
                    ></input>
                  </div>
                </form>
                <form ref={form} onSubmit={handleNewPasswordSubmit}>
                  <div>
                    <label
                      htmlFor="edit_pass"
                      className="edit-modal-field-label"
                    >
                      New Password
                    </label>
                    <input
                      onChange={(o) => {
                        setNewPassword(o.target.value);
                      }}
                      required
                      type="password"
                      autoComplete="new-password"
                      placeholder="Enter new password"
                      className="edit-profile-modal-input"
                      name="edit_pass"
                    ></input>
                    <input
                      className="edit-modal-field-submit"
                      type="submit"
                      value="Change Password"
                    ></input>
                  </div>
                </form>
              </Modal.Content>
            </Modal>
            <Button className="btn-drpdwn" onClick={handleLogout}>
              {" "}
              Sign Out{" "}
              <Icon name="sign out" style={{ marginLeft: "5px" }}></Icon>
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default ProfileHeader;
