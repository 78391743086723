import React, { useState, useRef } from "react";

import { Col, Row } from "react-bootstrap";
import { Popup } from "semantic-ui-react";
import emailjs from "@emailjs/browser";
import "./addMember.css";
import "./Storefront.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

function MemberSignupForm(props) {
  const form = useRef();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  // FIND ALL MEMBERS
  const emailTemplateParams = useRef({
    customerFirst: "",
    customerLast: "",
    customerSuffix: "",
    preferredStore: "",
    associate: "",
  });

  const [formState, setFormState] = useState({
    customerFirst: "",
    customerMiddle: "",
    customerLast: "",
    customerSuffix: "",
    customerPhone: "",
    customerEmail: "",
    customerAddress: "",
    communication: "",
    preferredStore: "",
    isRenewal: "",
    associate: "",
    clerk: "",
    card: "Pending",
    acknowledged: "No",
    role: "member",
  });
  //REGISTER CUSTOMER
  const submitNewCustomer = () => {
    axiosPrivate
      .post("/addMember", {
        customerFirst: formState.customerFirst,
        customerMiddle: formState.customerMiddle,
        customerLast: formState.customerLast,
        customerSuffix: formState.customerSuffix,
        customerPhone: formState.customerPhone,
        customerEmail: formState.customerEmail,
        customerAddress: formState.customerAddress,
        communication: formState.communication,
        preferredStore: formState.preferredStore,
        associate: formState.associate,
        clerk: formState.clerk,
        card: formState.card,
        acknowledged: formState.acknowledged,
        role: formState.role,
      })
      .then((response) => {
        if (response.status === 200) {
          if (formState.isRenewal === "No")
          {
            sendNewSignupEmail();
          }
          else
          {
            sendRenewalEmail();
          }
          props.getAllMembers();
          alert("Submission Successful");
        }
      })
      .catch((error) => {
        // server returns HTTP error code 422 if a record already exists in the database
        if (error.response.status === 422) {
          alert(error.response.data.message);
        } else {
          console.error(error);
          navigate("/");
        }
      });
  };

  //SENDING EMAIL NOTIFICATION OF EXEC SIGN UP
  const sendNewSignupEmail = () => {
    emailjs
      .send(
        "service_640rs57",
        "template_1ierxnq",
        emailTemplateParams.current,
        "user_QGlVs4Qz8yzIHPSfomOw6"
      )
      .then(
        (result) => {},
        (error) => {}
      );
  };

  const sendRenewalEmail = () => {
    emailjs
      .send(
        "service_640rs57",
        "template_cpec7nh",
        emailTemplateParams.current,
        "user_QGlVs4Qz8yzIHPSfomOw6"
        )
      .then(
        (result) => [],          
        (error) => {}
      );
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    emailTemplateParams.current = {
      customerFirst: formState.customerFirst,
      customerLast: formState.customerLast,
      customerSuffix: formState.customerSuffix,
      preferredStore: formState.preferredStore,
      associate: formState.associate,
    };
    submitNewCustomer();
    e.target.reset();
  };

  return (
    <form id="register-modal" ref={form} onSubmit={handleSubmit}>
      <Row>
        <Col>
          <label
            htmlFor="customerFirst"
            className="new-member-storefront-label"
          >
            First Name:<span className="required-asterisk">*</span>
          </label>
        </Col>
        <Col>
          <input
            className="new-member-storefront-input"
            required
            type="text"
            placeholder="(required)"
            name="customerFirst"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            htmlFor="customerMiddle"
            className="new-member-storefront-label"
          >
            Middle Name:
          </label>
        </Col>
        <Col>
          <input
            className="new-member-storefront-input"
            type="text"
            placeholder="(optional)"
            name="customerMiddle"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="customerLast" className="new-member-storefront-label">
            Last Name:<span className="required-asterisk">*</span>
          </label>
        </Col>
        <Col>
          <input
            required
            className="new-member-storefront-input"
            placeholder="(required)"
            type="text"
            name="customerLast"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="customerSuffix" className="new-member-storefront-label">
            Suffix:
          </label>
        </Col>
        <Col>
        <select
            name="suffix"
            value={formState.suffix}
            className="new-member-storefront-input"
            onChange={handleChange}
          >
            <option value="">No suffix</option>
            <option value="JR">Jr</option>
            <option value="SR">Sr</option>
            <option value="II">II</option>
            <option value="III">III</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            htmlFor="customerEmail"
            className="new-member-storefront-label"
          >
            Email Address:<span className="required-asterisk">*</span>
          </label>
        </Col>
        <Col>
          <input
            required
            className="new-member-storefront-input"
            placeholder="(required)"
            type="email"
            name="customerEmail"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            htmlFor="customerPhone"
            className="new-member-storefront-label"
          >
            Phone #:<span className="required-asterisk">*</span>
            <br></br>
            <span className="required-text">Format: 1234567890</span>
          </label>
        </Col>
        <Col>
          <input
            required
            className="new-member-storefront-input"
            type="tel"
            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
            placeholder="(required)"
            name="customerPhone"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            htmlFor="customerAddress"
            className="new-member-storefront-label"
          >
            Address:<span className="required-asterisk">*</span>
          </label>
        </Col>
        <Col>
          <input
            required
            className="new-member-storefront-input"
            type="text"
            placeholder="1349 S. Orange Blossom Trail, Apopka, FL 32703"
            name="customerAddress"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            htmlFor="communication"
            className="new-member-storefront-label"
          >
            Communication Preference:
          </label>
        </Col>
        <Col>
          <select
            name="communication"
            value={formState.communication}
            className="new-member-storefront-input"
            onChange={handleChange}
          >
            <option value="">Select a preference</option>
            <option value="Phone only">Phone Only</option>
            <option value="Email Only">Email Only</option>
            <option value="Both">Both</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col>
          <label
            htmlFor="preferredStore"
            className="new-member-storefront-label"
          >
            Preferred Store:<span className="required-asterisk">*</span>
          </label>
        </Col>
        <Col>
          <select
            onChange={handleChange}
            required
            className="new-member-storefront-input"
            name="preferredStore"
            value={formState.preferredStore}
          >
            <option value="">Select a store (required)</option>
            <option value="Apopka">Apopka</option>
            <option value="Casselberry">Casselberry</option>
            <option value="Clearwater">Clearwater</option>
            <option value="Fort Lauderdale">Fort Lauderdale</option>
            <option value="Fort Myers">Fort Myers</option>
            <option value="Lakeland">Lakeland</option>
            <option value="Sarasota">Sarasota</option>
            <option value="Tampa">Tampa</option>
            <option value="West Palm Beach">West Palm Beach</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col>
        <label
          htmlFor="isRenewal"
          className="new-member-storefront-label"
        >
          Is This a Renewal?<span className="required-asterisk">*</span>
        </label>
        </Col>
        <Col>
          <select
            onChange={handleChange}
            required
            className="new-member-storefront-input"
            name="isRenewal"
            value={formState.isRenewal}
          >
            <option value="">Select an option (required)</option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="associate" className="new-member-storefront-label">
            Sales Asscociate:<span className="required-asterisk">*</span>
            <br></br>
            <span className="required-text">
              Associate name required to receive credit for sign up.
            </span>
          </label>
        </Col>
        <Col>
          <input
            required
            className="new-member-storefront-input"
            name="associate"
            placeholder="John Smith (required)"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="clerk" className="new-member-storefront-label">
            Store User:
          </label>
        </Col>
        <Col>
          <input
            className="new-member-storefront-input"
            name="clerk"
            value={props.clerk}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="store" className="new-member-storefront-label">
            Store Location:
          </label>
        </Col>
        <Col>
          <input
            className="new-member-storefront-input"
            name="store"
            value={props.store}
            readOnly
          />
        </Col>
      </Row>
      <Row>
        <Col className="new-member-submit-container">
          <Popup
            trigger={
              <input
                className="new-member-storefront-submit"
                type="submit"
                value="Register Customer"
              />
            }
            position="top center"
            content="Ensure you have double checked all information before submission!"
          ></Popup>
        </Col>
      </Row>
    </form>
  );
}

export default MemberSignupForm;
