import React, { useState, useEffect } from "react";
import { Button, Icon, Feed, Card } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "./AdminAccount.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function PendingListItem(props) {
  const axiosPrivate = useAxiosPrivate();
  const cardStatus = "Yes Card";
  const memberName = props.value.first_name + " " + props.value.last_name + " " + props.value.Suffix;
  const navigate = useNavigate();
  let joinDate = new Date(props.value.dateJoined)
    .toUTCString()
    .split(" ")
    .slice(1, 4)
    .join(" ");

  const updateCardStatus = (id) => {
    axiosPrivate
      .put("/cardStatusChange", {
        id: id,
        card: cardStatus,
      })
      .then((response) => {
        if (response.status === 200) {
          axiosPrivate
            .post("/newCard", {
              id: id,
              newCardStatus: cardStatus,
              memberName: memberName,
              adminName: props.adminName,
            })
            .then(() => {
              props.getPending();
              props.getNotes();
            });
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  return (
    <Card className="dash-card">
      <Card.Content>
        <Row>
          <h2 className="dash-card-title">{memberName}</h2>
          <Col className="dash-mem-card-info">
            <Card.Description>
              <span className="dash-card-info-label">PHONE:</span>{" "}
              {props.value.phone.slice(0, 3) +
                "-" +
                props.value.phone.slice(3, 6) +
                "-" +
                props.value.phone.slice(6)}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">EMAIL:</span>{" "}
              {props.value.email}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">CARD STATUS:</span>{" "}
              {props.value.card}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">JOINED:</span> {joinDate}
            </Card.Description>
          </Col>
          <Col>
            <Row className="dash-card-btn-row">
              <Button
                onClick={() => {
                  updateCardStatus(props.value.id);
                }}
                className="dash-card-btn"
              >
                Set "Yes Card"
              </Button>
            </Row>
            <Row className="dash-card-btn-row">
              <Button
                onClick={() => {
                  navigate(`/executiveAccount/${props.value.id}`);
                }}
                className="dash-card-btn"
              >
                View Profile
              </Button>
            </Row>
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
}

function ContactReqItem(props) {
  const acknowledged = "Yes";
  const memberName = props.value.first_name + " " + props.value.last_name + " " + props.value.Suffix;
  const navigate = useNavigate();
  let joinDate = new Date(props.value.dateJoined)
    .toUTCString()
    .split(" ")
    .slice(1, 4)
    .join(" ");
  const axiosPrivate = useAxiosPrivate();

  const markResponded = (id) => {
    axiosPrivate
      .put("/adminResponded", {
        acknowledged: acknowledged,
        id: id,
      })
      .then((response) => {
        if (response.status === 200) {
          axiosPrivate
            .post("/newContactStatus", {
              id: id,
              newContactStatus: acknowledged,
              memberName: memberName,
              adminName: props.adminName,
            })
            .then(() => {
              props.getNeedContact();
              props.getNotes();
            });
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  return (
    <Card className="dash-card">
      <Card.Content>
        <Row>
          <h2 className="dash-card-title">{memberName}</h2>
          <Col className="dash-mem-card-info">
            <Card.Description>
              <span className="dash-card-info-label">PHONE:</span>{" "}
              {props.value.phone.slice(0, 3) +
                "-" +
                props.value.phone.slice(3, 6) +
                "-" +
                props.value.phone.slice(6)}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">EMAIL:</span>{" "}
              {props.value.email}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">CONTACT STATUS:</span>{" "}
              {props.value.acknowledged}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">JOINED:</span> {joinDate}
            </Card.Description>
          </Col>
          <Col>
            <Row className="dash-card-btn-row">
              <Button
                onClick={() => {
                  markResponded(props.value.id);
                }}
                className="dash-card-btn"
              >
                Mark as Contacted
              </Button>
            </Row>
            <Row className="dash-card-btn-row">
              <Button
                onClick={() => {
                  navigate(`/executiveAccount/${props.value.id}`);
                }}
                className="dash-card-btn"
              >
                View Profile
              </Button>
            </Row>
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
}

function FeedItem(props) {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  let created = new Date(props.value.createdAt)
    .toUTCString()
    .split(" ")
    .slice(1, 4)
    .join(" ");

  //DELETE NOTE
  const deleteNote = (id) => {
    axiosPrivate
      .delete(`/deleteNote/${id}`)
      .then(() => {
        props.getNotes();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  return (
    <Feed.Event>
      <Feed.Content style={{ color: "#dc1b29" }}>
        <Icon
          onClick={() => {
            deleteNote(props.value.id);
          }}
          name="x"
        />
        <Feed.Summary>
          <Feed.User
            style={{
              cursor: "default",
              color: " black",
              fontSize: "15px",
            }}
          >
            <Icon name="user circle" /> {props.value.adminName}
            {props.value.noteHeader}
          </Feed.User>
          <Feed.Date style={{ color: "#dc1b29" }}>{created}</Feed.Date>
        </Feed.Summary>
        <Feed.Meta>
          <Feed.User style={{ color: "black" }}>
            {props.value.memberName}
          </Feed.User>
        </Feed.Meta>
        <Feed.Extra
          style={{
            width: "100%",
            color: "black",
          }}
        >
          {props.value.note}
          {props.value.newFirst}
          {props.value.newMiddle}
          {props.value.newLast}
          {props.value.newPhone}
          {props.value.newEmail}
          {props.value.newAddress}
          {props.value.newPassword}
          {props.value.newCard}
          {props.value.newStore}
          {props.value.newCommunication}
          {props.value.newContactStat}
          <p
            style={{
              color: "#a9abad",
            }}
          >
            {props.value.requestNote}
          </p>
        </Feed.Extra>
      </Feed.Content>
    </Feed.Event>
  );
}

function MainDashboard(props) {
  const [pendingCardList, setPendingCardList] = useState("");
  const [contactNeeded, setContactNeeded] = useState("");
  const [searchTermPending, setPendingSearchTerm] = useState("");
  const [searchTermContact, setContactSearchTerm] = useState("");
  const [notesList, setNotesList] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  //NEW PENDING CARD MEMBERS
  const getPending = () => {
    axiosPrivate.get("/PendingCardMembers").then((response) => {
      setPendingCardList(response.data);
    });
  };

  //GET CUSTOMER WHO NEED TO BE CONTACTED
  const getNeedContact = () => {
    axiosPrivate
      .get("/getNeedContact")
      .then((response) => {
        setContactNeeded(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //GET ALL THE NOTES
  const getNotes = () => {
    axiosPrivate
      .get("/getNotes")
      .then((response) => {
        setNotesList(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //propogate list of members who are pending a new membership card
  const pendingList = Object.keys(pendingCardList)
    .filter((member) => {
      if (searchTermPending === "") {
        return member;
      } else if (
        pendingCardList[member].first_name
          .toLowerCase()
          .includes(searchTermPending.toLowerCase()) ||
        pendingCardList[member].last_name
          .toLowerCase()
          .includes(searchTermPending.toLowerCase())
      ) {
        return member;
      }
      return null;
    })
    .map((mem) => (
      <PendingListItem
        key={mem}
        value={pendingCardList[mem]}
        getPending={getPending}
        getNotes={getNotes}
        adminName={props.adminName}
      />
    ));

  //propogate list of members who need contact
  const contactReqList = Object.keys(contactNeeded)
    .filter((member) => {
      if (searchTermContact === "") {
        return member;
      } else if (
        contactNeeded[member].first_name
          .toLowerCase()
          .includes(searchTermContact.toLowerCase()) ||
        contactNeeded[member].last_name
          .toLowerCase()
          .includes(searchTermContact.toLowerCase())
      ) {
        return member;
      }
      return null;
    })
    .map((mem) => (
      <ContactReqItem
        key={mem}
        value={contactNeeded[mem]}
        getNeedContact={getNeedContact}
        getNotes={getNotes}
        adminName={props.adminName}
      />
    ));

  const activityFeed = Object.keys(notesList).map((note) => (
    <FeedItem key={note} value={notesList[note]} getNotes={getNotes} />
  ));

  useEffect(() => {
    getPending();
    getNeedContact();
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row className="dash-title-container">
        <Col></Col>
        <Col>
          <h1 className="dash-title">Dashboard</h1>
          <p className="dash-title-underline"></p>
        </Col>
        <Col></Col>
      </Row>
      <Row className="dash-content">
        <Col sm={6} className="card-list-col">
          <h2 className="ra-title">
            Pending Card{" "}
            <span className="title-num">({pendingCardList.length})</span>
          </h2>
          <p className="chat-line"></p>
          <div className="dash-search-container">
            <input
              type="text"
              placeholder="Search First or Last Name"
              className="dash-search"
              onChange={(event) => {
                setPendingSearchTerm(event.target.value);
              }}
            ></input>
          </div>
          <div className="dash-list-container">{pendingList}</div>
        </Col>
        <Col sm={6} className="card-list-col">
          <h2 className="ra-title">
            Contact Required{" "}
            <span className="title-num">({contactNeeded.length})</span>
          </h2>
          <p className="chat-line"></p>
          <div className="dash-search-container">
            <input
              type="text"
              placeholder="Search First or Last Name"
              className="dash-search"
              onChange={(event) => {
                setContactSearchTerm(event.target.value);
              }}
            ></input>
          </div>
          <div className="dash-list-container">{contactReqList}</div>
        </Col>
        <Row className="recent-activity">
          <h2 className="ra-title">Recent Activity</h2>
          <p className="chat-line"></p>
          <Container
            className="recent-activity-container"
            style={{ overflow: "scroll" }}
          >
            {activityFeed}
          </Container>
        </Row>
      </Row>
    </div>
  );
}

export default MainDashboard;
