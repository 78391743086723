import React from "react";
import { Row, Col } from "react-bootstrap";

function CalendarDashbaord() {
  return (
    <div>
      <Row className="dash-title-container">
        <Col></Col>
        <Col>
          <h1 className="dash-title">Calendar</h1>
          <p className="dash-title-underline"></p>
        </Col>
        <Col></Col>
      </Row>
      <Row>
        <p style={{ textAlign: "center", fontSize: "larger" }}>Coming Soon!</p>
      </Row>
    </div>
  );
}

export default CalendarDashbaord;
