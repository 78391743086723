import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  List,
  Container,
  Modal,
} from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import "./RequestDashboard.css";
import "./AdminAccount.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import EditReqForm from "./RequestEditForm";

function ListItem(props) {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  let reqCreated = new Date(props.value.date_created)
    .toUTCString()
    .split(" ")
    .slice(1, 4)
    .join(" ");
  let reqUpdated = new Date(props.value.date_updated)
    .toUTCString()
    .split(" ")
    .slice(1, 4)
    .join(" ");

  const [open, setOpen] = React.useState(false);
  const [newStatus, setNewStatus] = useState("");

  const handleStatusChange = (e) => {
    const value = e.target.value.toUpperCase();
    setNewStatus(value);
  };

  //UPDATE REQUEST STATUS
  const updateStatus = (id) => {
    axiosPrivate
      .put("/statusUpdate", {
        status: newStatus,
        id: id,
      })
      .then((response) => {
        if (response.status === 200) {
          props.refreshData();
          alert("Status Changed!");
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  return (
    <Card className="dash-card" id="req-card">
      <Card.Content>
        <Row columns={3}>
          <h2 className="dash-card-title">
            <button onClick={() => {
                navigate(`/executiveAccount/${props.value.memberIdentity}`);
              }}>{props.value.memberName}
            {" (#"}
            {props.value.memberIdentity}
            {")"}</button>
            {" - "}
            {props.value.brand} {props.value.item}
          </h2>
          <p id="req-status">
            <span className="dash-card-info-label">Status: </span>
            {props.value.status}
          </p>
          <Col>
            <Card.Description>
              <span className="dash-card-info-label">Category:</span>{" "}
              {" " + props.value.category}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">SKU:</span>{" "}
              {" " + props.value.sku}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">Brand:</span>{" "}
              {" " + props.value.brand}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">Item:</span>{" "}
              {" " + props.value.item}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">Qty:</span>{" "}
              {" " + props.value.quantity}
            </Card.Description>
          </Col>
          <Col>
            <Card.Description>
              <span className="dash-card-info-label">Created:</span>
              {" " + reqCreated}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">Updated:</span>
              {" " + reqUpdated}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">Source:</span>{" "} {props.value.source === null ? "" : props.value.source}
            </Card.Description>
            <Card.Description>
              <span className="dash-card-info-label">Note:</span>{" "} {props.value.note === null ? "" : props.value.note}
            </Card.Description>
          </Col>
          <Col>
            <Row className="dash-card-btn-row">
              <select
                className="dash-card-select"
                onChange={handleStatusChange}
              >
                <option value="">-- Choose Status --</option>
                <option value="Pending">Pending</option>
                <option value="On Hold">On Hold</option>
                <option value="Back Order">Back Order</option>
                <option value="Ordered">Ordered</option>
                <option value="Completed">Completed</option>
                <option value="Canceled">Canceled</option>
                <option value="Offered">Offered</option>
              </select>
            </Row>
            <Row className="dash-card-btn-row">
              <Button
                className="dash-card-btn"
                disabled={newStatus === "" ? true : false}
                onClick={() => {
                  updateStatus(props.value.id);
                }}
              >
                Update Status
              </Button>
            </Row>
            <Row className="dash-card-btn-row">
              <Modal
                style={{
                  height: "75%",
                  width: "600px",
                  position: "fixed",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  borderRadius: "0px",
                }}
                className="item-modal-market"
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open[props.value]}
                trigger={
                  <Button className="dash-card-btn">
                    Edit Request Details
                  </Button>
                }
              >
                <Modal.Header>
                  Edit Request Details
                  <p className="modal-header-underline"></p>
                </Modal.Header>
                <Modal.Content>
                  <EditReqForm
                    value={props.value}
                    refreshData={props.refreshData}
                  />
                </Modal.Content>
              </Modal>
            </Row>
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
}

function RequestList(props) {
  const requestList = props.requestList;
  const [searchTerm, setSearchTerm] = useState("");
  const requestFeed = Object.keys(requestList)
    .filter((request) => {
      if (searchTerm === "") {
        return request;
      } else if (
        requestList[request].category
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        requestList[request].sku
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        requestList[request].memberName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        requestList[request].brand
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        requestList[request].item
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        requestList[request].memberIdentity.includes(searchTerm)
      ) {
        return request;
      }
      return null;
    })
    .map((req) => (
      <ListItem
        key={req}
        value={requestList[req]}
        refreshData={props.refreshData}
      />
    ));

  return (
    <div>
      <h2 className="ra-title">
        {props.dashTitle}{" "}
        <span className="title-num">({requestList.length})</span>
      </h2>
      <p className="chat-line"></p>
      <div className="dash-search-container">
        <input
          type="text"
          placeholder="Search First or Last Name"
          className="dash-search"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        ></input>
      </div>
      <Container
        style={{
          overflow: "auto",
          maxHeight: "1000px",
          width: "100%",
        }}
      >
        {requestFeed}
      </Container>
    </div>
  );
}

function RequestDashboard() {
  const [orderedRequests, setOrderedRequests] = useState("");
  const [completedRequests, setCompletedRequests] = useState("");
  const [onHoldRequest, setOnholdRequest] = useState("");
  const [pendingRequests, setPendingRequests] = useState("");
  const [backOrderRequest, setBackorderRequest] = useState("");
  const [canceledRequests, setCanceledRequests] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [displayedDash, setDisplayedDash] = useState("");
  const renderDash = (displayedDash) => {
    switch (displayedDash) {
      case "orderedReq":
        return (
          <RequestList
            dashTitle="Ordered Requests"
            requestList={orderedRequests}
            refreshData={refreshData}
          />
        );
      case "pendingReq":
        return (
          <RequestList
            dashTitle="Pending Requests"
            requestList={pendingRequests}
            refreshData={refreshData}
          />
        );
      case "onHoldReq":
        return (
          <RequestList
            dashTitle="On Hold Requests"
            requestList={onHoldRequest}
            refreshData={refreshData}
          />
        );
      case "backOrderedReq":
        return (
          <RequestList
            dashTitle="Back Ordered Requests"
            requestList={backOrderRequest}
            refreshData={refreshData}
          />
        );
      case "completedReq":
        return (
          <RequestList
            dashTitle="Completed Requests"
            requestList={completedRequests}
            refreshData={refreshData}
          />
        );
      case "canceledReq":
        return (
          <RequestList
            dashTitle="Canceled Requests"
            requestList={canceledRequests}
            refreshData={refreshData}
          />
        );
      default:
        return (
          <RequestList
            dashTitle="Pending Requests"
            requestList={pendingRequests}
            refreshData={refreshData}
          />
        );
    }
  };

  const handleOrderedReqBtn = () => {
    setDisplayedDash("orderedReq");
  };
  const handlePendingReqBtn = () => {
    setDisplayedDash("pendingReq");
  };
  const handleOnHoldReqBtn = () => {
    setDisplayedDash("onHoldReq");
  };
  const handleBackOrderReqBtn = () => {
    setDisplayedDash("backOrderedReq");
  };
  const handleCompletedReqBtn = () => {
    setDisplayedDash("completedReq");
  };
  const handleCanceledReqBtn = () => {
    setDisplayedDash("canceledReq");
  };

  //GET ALL ACTIVE REQUESTS
  const getOrderedRequests = () => {
    axiosPrivate
      .get("/orderedRequests")
      .then((response) => {
        setOrderedRequests(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  //GET PENDING REQUESTS
  const getPendingRequests = () => {
    axiosPrivate
      .get("/pendingRequests")
      .then((response) => {
        setPendingRequests(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  //GET ALL CONCLUDED REQUESTS
  const getAllCompleted = () => {
    axiosPrivate
      .get("/completedRequests")
      .then((response) => {
        setCompletedRequests(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  //GET ON HOLD REQUESTS
  const getOnHoldRequests = () => {
    axiosPrivate
      .get("/onholdRequests")
      .then((response) => {
        setOnholdRequest(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  //GET BACK ORDERED REQUESTS
  const getBackOrderedRequests = () => {
    axiosPrivate
      .get("/backorderedRequests")
      .then((response) => {
        setBackorderRequest(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const getCanceledRequests = () => {
    axiosPrivate
      .get("/canceledRequests")
      .then((response) => {
        setCanceledRequests(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  // ********** DOWNLAOD THE REQUEST LISTS ***********
  const objectToCsv = function (data) {
    const csvRows = [];

    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });

      csvRows.push(values.join(","));
    }
    return csvRows.join("\n");
  };

  const download = function (data) {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "download.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const DownloadRequests = async function () {
    try {
      const res = await axiosPrivate("/getAllRequests");
      const data = res.data;
      const csvData = objectToCsv(data);
      download(csvData);
    } catch (err) {
      console.error(err);
    }
  };

  const refreshData = async function () {
    getAllCompleted();
    getOnHoldRequests();
    getBackOrderedRequests();
    getPendingRequests();
    getOrderedRequests();
    getCanceledRequests();
  };

  useEffect(() => {
    getOrderedRequests();
    getAllCompleted();
    getOnHoldRequests();
    getBackOrderedRequests();
    getPendingRequests();
    getCanceledRequests();
  }, []);

  return (
    <div>
      <Row className="dash-title-container">
        <Col></Col>
        <Col>
          <h1 className="dash-title">Requests</h1>
          <p className="dash-title-underline"></p>
        </Col>
        <Col className="dnl-btn-container">
          <button className="dnl-btn" onClick={DownloadRequests}>
            Download All Requests
          </button>
        </Col>
      </Row>
      <Row className="mem-dash-content">
        <Col sm={2} className="side-dash-nav">
          <List>
            <List.Item>
              <button className="side-dash-btn" onClick={handlePendingReqBtn}>
                Pending
              </button>
            </List.Item>
            <List.Item>
              <button className="side-dash-btn" onClick={handleOnHoldReqBtn}>
                On Hold
              </button>
            </List.Item>
            <List.Item>
              <button className="side-dash-btn" onClick={handleBackOrderReqBtn}>
                Back Order
              </button>
            </List.Item>
            <List.Item>
              <button className="side-dash-btn" onClick={handleOrderedReqBtn}>
                Ordered
              </button>
            </List.Item>
            <List.Item>
              <button className="side-dash-btn" onClick={handleCompletedReqBtn}>
                Completed
              </button>
            </List.Item>
            <List.Item>
              <button className="side-dash-btn" onClick={handleCanceledReqBtn}>
                Canceled
              </button>
            </List.Item>
          </List>
        </Col>
        <Col sm={10}>{renderDash(displayedDash)}</Col>
      </Row>
    </div>
  );
}
export default RequestDashboard;
