import React, { useState } from "react";
import { Button, Card, Table, Modal } from "semantic-ui-react";

import "./addMember.css";
import "./Storefront.css";
import RenewMemberForm from "./RenewMemberForm";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

function RequestListItem(props) {
  let update = new Date(props.req.date_updated)
    .toUTCString()
    .split(" ")
    .slice(0, 4)
    .join(" ");
  return (
    <Table.Row>
      <Table.Cell>{props.req.category}</Table.Cell>
      <Table.Cell>{props.req.item}</Table.Cell>
      <Table.Cell>{props.req.sku}</Table.Cell>
      <Table.Cell>{props.req.quantity}</Table.Cell>
      <Table.Cell> {props.req.status}</Table.Cell>
      <Table.Cell>{update}</Table.Cell>
    </Table.Row>
  );
}

function RequestList(props) {
  const requestList = props.requestList;
  const requestFeed = Object.keys(requestList).map((req) => (
    <RequestListItem key={req} req={requestList[req]} />
  ));
  return requestFeed;
}

function StorefrontViewMember(props) {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [requestList, setRequestList] = useState("");
  // FIND ALL MEMBERS

  const [open, setOpen] = React.useState(false);

  //GETTING SPECIFIC MEMBER REQUESTS
  const getMemberRequests = () => {
    axiosPrivate
      .get("/getMemberRequests", {
        params: { id: props.value.id },
      })
      .then((response) => {
        const result = response.data;
        setRequestList(result);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  return (
    <Modal
      className="members-info-modal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open[props.value]}
      style={{
        height: "90%",
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "0px",
      }}
      trigger={
        <Button
          onClick={getMemberRequests}
          className="member-info-btn-storefront"
          content="View"
        />
      }
    >
      <Modal.Header>
        Customer Profile information
        <p className="modal-header-underline"></p>
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <h1>
            {props.value.first_name} {props.value.last_name} {props.value.Suffix}
          </h1>
          <h3>Member Number: {props.value.number}</h3>
          <p>
            {props.value.phone.slice(0, 3) +
              "-" +
              props.value.phone.slice(3, 6) +
              "-" +
              props.value.phone.slice(6)}
          </p>
          <p>{props.value.email}</p>
          <p>{props.value.address}</p>
          <p>Date Joined: {props.joinDate}</p>
          <p>Date Expiring: {props.expiring}</p>
          <p className="profile-info-underline"></p>
        </Modal.Description>
        {/* START CARD CONTENT FOR THE REQURST */}
        <h1>Member Requests</h1>
        <p></p>
        <Card.Content
          style={{
            overflowY: "scroll",
            height: "300px",
          }}
        >
          <Table celled striped color="red">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Item</Table.HeaderCell>
                <Table.HeaderCell>UPC / SKU</Table.HeaderCell>
                <Table.HeaderCell>Quantity</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Date Updated</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <RequestList requestList={requestList} />
            </Table.Body>
          </Table>
        </Card.Content>
        <div className="renew-div">
          <h1>Renew Membership</h1>
          <RenewMemberForm
            value={props.value}
            getAllMembers={props.getAllMembers}
          />
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default StorefrontViewMember;
