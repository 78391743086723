import React, { useState, useEffect } from "react";
import { List } from "semantic-ui-react";
import { Container, Row } from "react-bootstrap";
import "./AdminAccount.css";
import MembersDashboard from "./MembersDashboard";
import RequestDashboard from "./RequestDashboard";
import AddNewMember from "./AddMember";
import AddAdminDashboard from "./AddAdminDashboard";
import CatalogDashboard from "./CatalogDashboard";
import CalendarDashbaord from "./CalendarDashboard";
import AdminHeader from "./AdminHeader";
import MainDashboard from "./MainDashboard";
import { useParams } from "react-router";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

function AdminAccount() {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  //LOGGED IN ADMIN
  const { id } = useParams();
  const [adminDetails, setAdminDetails] = useState("");
  const getAdmin = () => {
    axiosPrivate
      .get("/getSingleAdmin", {
        params: { id: id },
      })
      .then((response) => {
        const result = response.data;
        setAdminDetails(result[0]);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const adminName = adminDetails.first_name + " " + adminDetails.last_name;
  const greetingName = adminDetails.first_name;

  const [displayedDash, setDisplayedDash] = useState("");

  const renderDash = (displayedDash, adminName) => {
    switch (displayedDash) {
      case "mainDash":
        return <MainDashboard adminName={adminName} />;
      case "memDash":
        return <MembersDashboard />;
      case "reqDash":
        return <RequestDashboard />;
      case "marketDash":
        return <CatalogDashboard />;
      case "calendarDash":
        return <CalendarDashbaord />;
      case "addMemDash":
        return <AddNewMember adminName={adminName} />;
      case "addAdminDash":
        return <AddAdminDashboard />;
      default:
        return <MainDashboard adminName={adminName} />;
    }
  };

  const handleDashBtn = (e) => {
    setDisplayedDash("mainDash");
  };
  const handleMembersBtn = (e) => {
    setDisplayedDash("memDash");
  };
  const handleReqBtn = (e) => {
    setDisplayedDash("reqDash");
  };
  const handleMarketBtn = (e) => {
    setDisplayedDash("marketDash");
  };
  const handleCalendarBtn = (e) => {
    setDisplayedDash("calendarDash");
  };
  const handleAddMemBtn = (e) => {
    setDisplayedDash("addMemDash");
  };
  const handleAddAdminBtn = (e) => {
    setDisplayedDash("addAdminDash");
  };

  useEffect(() => {
    getAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="adminMain">
      <Container fluid="md">
        <AdminHeader greetingName={greetingName} />
        {/* TOP NAVIGATION */}
        <Row className="navigation-container">
          <List className="nav-list">
            <List.Item
              className="menu-item"
              id="dashboard-btn"
              onClick={handleDashBtn}
            >
              Dashboard
            </List.Item>
            <List.Item
              className="menu-item"
              id="members-btn"
              onClick={handleMembersBtn}
            >
              Members
            </List.Item>
            <List.Item
              className="menu-item"
              id="requests-btn"
              onClick={handleReqBtn}
            >
              Requests
            </List.Item>
            <List.Item
              className="menu-item"
              id="market-btn"
              onClick={handleMarketBtn}
            >
              Market
            </List.Item>
            <List.Item
              className="menu-item"
              id="calendar-btn"
              onClick={handleCalendarBtn}
            >
              Calendar
            </List.Item>
            <List.Item
              className="menu-item"
              id="add-member-btn"
              onClick={handleAddMemBtn}
            >
              Add Member
            </List.Item>
            <List.Item
              className="menu-item"
              id="add-admin-btn"
              onClick={handleAddAdminBtn}
            >
              Add Admin
            </List.Item>
          </List>
        </Row>
        {/* Displayed dashboard section conditionally rendered */}
        <Row className="tab-content">
          {renderDash(displayedDash, adminName)}
        </Row>
      </Container>
    </div>
  );
}

export default AdminAccount;
