import React, { useState, useEffect } from "react";
import SimpleFileUpload from "react-simple-file-upload";
import {
  Input,
  Button,
  Table,
  Form,
  Modal,
  List,
  Container,
} from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import "./CatalogDashboard.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

function EditItemForm(props) {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  // UPDATE MARKET ITEM
  const updateMarketItem = (id) => {
    axiosPrivate
      .put("/updateMarketItem", {
        category: formState.category,
        make: formState.make,
        model: formState.model,
        sku: formState.sku,
        price: formState.price,
        quantity: formState.qty,
        id: id,
      })
      .then((response) => {
        if (response.status === 200) {
          props.doTasks();
          alert("Item Updated");
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      })
  }

  const changeImage = (id) => {
    axiosPrivate
      .put("/ChangeMarketItemImage", {
        newImage: imageUrl,
        id: id,
      })
      .then((response) => {
        if (response.status === 200) {
          props.doTasks();
          alert("Image Updated");
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const [uploadedImages, setUploadedImages] = useState([]);
  const [imageUrl, setImageUrl] = useState(props.item.imageURL);

  function handleUploadChange(url) {
    setImageUrl(url);
    setUploadedImages([...uploadedImages, url]);
  }

  const [formState, setFormState] = useState({
    category: props.item.Category,
    make: props.item.Make,
    model: props.item.Model,
    sku: props.item.Sku,
    qty: props.item.Quantity,
    price: props.item.Price,
    imageUrl: props.item.imageURL,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formState.imageUrl !== imageUrl) {
      changeImage(e.target.id.value);
    } else if (formState.category !== props.item.Category) {
      updateMarketItem(e.target.id.value);
    } else if (formState.make !== props.item.Make) {
      updateMarketItem(e.target.id.value);
    } else if (formState.model !== props.item.Model) {
      updateMarketItem(e.target.id.value);
    } else if (formState.price !== props.item.Price) {
      updateMarketItem(e.target.id.value);
    } else if (formState.qty !== props.item.Quantity) {
      updateMarketItem(e.target.id.value);
    } else if (formState.sku !== props.item.Sku) {
      updateMarketItem(e.target.id.value);
    } else {
      alert("Nothing was changed!");
    }
  };

  return (
    <Form className="modal-edit-form" onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Field width={16}>
            <label>Category</label>
            <select
              onChange={handleChange}
              name="category"
              value={formState.category}
            >
              <option value="Accessories">Accessories</option>
              <option value="Ammunition">Ammunition</option>
              <option value="Class3">Class 3</option>
              <option value="Firearms">Firearms</option>
            </select>
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Make</label>
            <Input
              name="make"
              onChange={handleChange}
              defaultValue={formState.make}
            ></Input>
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Model</label>
            <Input
              name="model"
              onChange={handleChange}
              defaultValue={formState.model}
            ></Input>
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Sku</label>
            <Input
              name="sku"
              onChange={handleChange}
              defaultValue={formState.sku}
            ></Input>
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Price</label>
            <Input
              name="price"
              type="number"
              min="0"
              step="0.01"
              onChange={handleChange}
              defaultValue={formState.price}
            ></Input>
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Quantity</label>
            <Input
              name="qty"
              type="number"
              min="0"
              onChange={handleChange}
              defaultValue={formState.qty}
            ></Input>
          </Form.Field>
        </Col>
        <Col>
          <Form.Field>
            <label>Update Product Image</label>
            <div className="edit-img-wrapper">
              {uploadedImages.length ? (
                <img
                  src={uploadedImages[uploadedImages.length - 1]}
                  height="200px"
                  alt="Product"
                />
              ) : (
                <img
                  src={formState.imageUrl}
                  height="200px"
                  alt="Current Product"
                />
              )}
            </div>
            <div className="upload-wrapper">
              <SimpleFileUpload
                apiKey="0b044490ae9cd1af81a6abc40c19816c"
                onSuccess={handleUploadChange}
                preview={false}
              />
            </div>
          </Form.Field>
        </Col>
        <input
          style={{ display: "none" }}
          name="id"
          value={props.item.id}
          readOnly
        ></input>
      </Row>
      <Row>
        <Form.Field width={16}>
          <Button content="Submit"></Button>
        </Form.Field>
      </Row>
    </Form>
  );
}

function AddItemForm(props) {
  // FILE UPLOAD
  const [uploadedImages, setUploadedImages] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  function handleUpload(url) {
    setImageUrl(url);
    setUploadedImages([...uploadedImages, url]);
  }

  const [formState, setFormState] = useState({
    category: "",
    make: "",
    model: "",
    sku: "",
    qty: "",
    price: "",
    imageUrl: "",
  });

  const AddProduct = () => {
    axiosPrivate
      .post("/addProductCatalog", {
        category: formState.category,
        make: formState.make,
        model: formState.model,
        sku: formState.sku,
        qty: formState.qty,
        price: formState.price,
        imageUrl: imageUrl,
      })
      .then(() => {
        alert("New Product Added");
        props.doTasks();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    AddProduct();
    e.target.reset();
  };

  return (
    <Form className="modal-edit-form" onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Field width={16}>
            <label>Category</label>
            <select
              onChange={handleChange}
              required
              name="category"
              value={formState.category}
            >
              <option value="">Select a Category</option>
              <option value="Accessories">Accessories</option>
              <option value="Ammunition">Ammunition</option>
              <option value="Class3">Class 3</option>
              <option value="Firearms">Firearms</option>
            </select>
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Make</label>
            <Input name="make" required onChange={handleChange} />
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Model</label>
            <Input name="model" required onChange={handleChange} />
          </Form.Field>
          <Form.Field width={16} inline>
            <label>UPC / SKU</label>
            <Input required name="sku" onChange={handleChange} />
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Quantity Available</label>
            <Input
              name="qty"
              type="number"
              min="0"
              required
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Price</label>
            <Input
              name="price"
              type="number"
              min="0"
              step="0.01"
              required
              onChange={handleChange}
            />
          </Form.Field>
        </Col>
        <Col>
          <Form.Field>
            <label>Product Image</label>
            <div className="upload-wrapper">
              <SimpleFileUpload
                apiKey="0b044490ae9cd1af81a6abc40c19816c"
                onSuccess={handleUpload}
                preview={false}
              />
            </div>
            <div className="edit-img-wrapper">
              {uploadedImages.length ? (
                <img
                  src={uploadedImages[uploadedImages.length - 1]}
                  height="200px"
                  alt="Product"
                />
              ) : (
                <p>Uploaded Image will appear here</p>
              )}
            </div>
          </Form.Field>
        </Col>
      </Row>
      <Row>
        <Form.Field width={16}>
          <Button content="Submit"></Button>
        </Form.Field>
      </Row>
    </Form>
  );
}

function MarketItem(props) {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  let formatCurrency = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
  });

  const [open2, setOpen2] = React.useState(false);

  const handleDelete = (id) => {
    if (
      window.confirm("Are you sure you want to delete this product?") === true
    ) {
      DeleteProduct(id);
    } else {
      return false;
    }
  };

  //DELETE PRODUCT
  const DeleteProduct = (id) => {
    axiosPrivate
      .delete(`/deleteProduct/${id}`)
      .then(() => {
        props.doTasks();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  return (
    <Table celled striped className="admin-market-product-table">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="7">
            {props.value.Make}
            {" - "}
            {props.value.Model}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row className="product-table-label-row">
          <Table.Cell>Image</Table.Cell>
          <Table.Cell>Category</Table.Cell>
          <Table.Cell>Price</Table.Cell>
          <Table.Cell>Sku/UPC</Table.Cell>
          <Table.Cell>Quantity</Table.Cell>
          <Table.Cell>Controls</Table.Cell>
        </Table.Row>
        <Table.Row>
          <Table.Cell collapsing className="product-image-cell">
            <img
              src={props.value.imageURL}
              className="img-height"
              alt="Product"
            ></img>
          </Table.Cell>
          <Table.Cell>{props.value.Category}</Table.Cell>
          <Table.Cell>{formatCurrency.format(props.value.Price)}</Table.Cell>
          <Table.Cell>{props.value.Sku}</Table.Cell>
          <Table.Cell>{props.value.Quantity}</Table.Cell>
          <Table.Cell>
            <Modal
              style={{
                height: "75%",
                width: "600px",
                position: "fixed",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "0px",
              }}
              className="item-modal-market"
              onClose={() => setOpen2(false)}
              onOpen={() => setOpen2(true)}
              open={open2[props.value]}
              trigger={<button className="dnl-btn">Edit Item</button>}
            >
              <Modal.Header>
                Edit Item
                <p className="modal-header-underline"></p>
              </Modal.Header>
              <Modal.Content>
                <EditItemForm item={props.value} doTasks={props.doTasks} />
              </Modal.Content>
            </Modal>
            <Button
              onClick={() => {
                handleDelete(props.value.id);
              }}
              negative
            >
              {" "}
              Delete Item{" "}
            </Button>
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}

function MarketItemList(props) {
  const itemList = props.itemList;
  const itemFeed = Object.keys(itemList).map((item) => (
    <MarketItem key={item} value={itemList[item]} doTasks={props.doTasks} />
  ));
  return (
    <div>
      <h2 className="ra-title">
        {props.dashTitle} <span className="title-num">({itemList.length})</span>
      </h2>
      <p className="chat-line"></p>
      <Container
        style={{
          overflow: "auto",
          maxHeight: "1000px",
          width: "100%",
        }}
      >
        {itemFeed}
      </Container>
    </div>
  );
}

function CatalogDashboard() {
  const [catalog, setCatalog] = useState("");
  const [accessories, setAccessories] = useState("");
  const [firearms, setFirearms] = useState("");
  const [ammunition, setAmmunition] = useState("");
  const [class3, setClass3] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const [displayedDash, setDisplayedDash] = useState("");
  const renderDash = (displayedDash) => {
    switch (displayedDash) {
      case "allItems":
        return (
          <MarketItemList
            itemList={catalog}
            dashTitle="All Items"
            doTasks={doTasks}
          />
        );
      case "accessories":
        return (
          <MarketItemList
            itemList={accessories}
            dashTitle="Accessories"
            doTasks={doTasks}
          />
        );
      case "ammo":
        return (
          <MarketItemList
            itemList={ammunition}
            dashTitle="Ammunition"
            doTasks={doTasks}
          />
        );
      case "class3":
        return (
          <MarketItemList
            itemList={class3}
            dashTitle="Class 3"
            doTasks={doTasks}
          />
        );
      case "firearms":
        return (
          <MarketItemList
            itemList={firearms}
            dashTitle="Firearms"
            doTasks={doTasks}
          />
        );
      default:
        return (
          <MarketItemList
            itemList={catalog}
            dashTitle="All Items"
            doTasks={doTasks}
          />
        );
    }
  };

  const handleAllItemsBtn = () => {
    setDisplayedDash("allItems");
  };
  const handleAccessoriesBtn = () => {
    setDisplayedDash("accessories");
  };
  const handleAmmunitionBtn = () => {
    setDisplayedDash("ammo");
  };
  const handleClass3Btn = () => {
    setDisplayedDash("class3");
  };
  const handleFirearmsBtn = () => {
    setDisplayedDash("firearms");
  };

  const GetCatalog = () => {
    axiosPrivate
      .get("/getCatalogNow")
      .then((response) => {
        setCatalog(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const GetAccessories = () => {
    axiosPrivate
      .get("/getAccessories")
      .then((response) => {
        setAccessories(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const GetFirearms = () => {
    axiosPrivate
      .get("/getFirearms")
      .then((response) => {
        setFirearms(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const GetAmmunition = () => {
    axiosPrivate
      .get("/getAmmunition")
      .then((response) => {
        setAmmunition(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const GetClass3 = () => {
    axiosPrivate
      .get("/getClass3")
      .then((response) => {
        setClass3(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const doTasks = () => {
    GetCatalog();
    GetAccessories();
    GetFirearms();
    GetAmmunition();
    GetClass3();
  };

  useEffect(() => {
    GetCatalog();
    GetAccessories();
    GetFirearms();
    GetAmmunition();
    GetClass3();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row className="dash-title-container">
        <Col></Col>
        <Col>
          <h1 className="dash-title">Market</h1>
          <p className="dash-title-underline"></p>
        </Col>
        <Col className="dnl-btn-container">
          <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            style={{
              height: "75%",
              width: "600px",
              position: "fixed",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "0px",
            }}
            trigger={<button className="dnl-btn"> Add Item </button>}
            className="item-modal-market"
          >
            <Modal.Actions>
              <Button icon="x" onClick={() => setOpen(false)} />
            </Modal.Actions>
            <Modal.Header>
              Add Item
              <p className="modal-header-underline"></p>
            </Modal.Header>
            <Modal.Content>
              <AddItemForm doTasks={doTasks} />
            </Modal.Content>
          </Modal>
        </Col>
      </Row>
      <Row className="mem-dash-content">
        <Col sm={2} className="side-dash-nav">
          <List>
            <List.Item>
              <button onClick={handleAllItemsBtn} className="side-dash-btn">
                All Items
              </button>
            </List.Item>
            <List.Item>
              <button onClick={handleAccessoriesBtn} className="side-dash-btn">
                Accessories
              </button>
            </List.Item>
            <List.Item>
              <button onClick={handleAmmunitionBtn} className="side-dash-btn">
                Ammunition
              </button>
            </List.Item>
            <List.Item>
              <button onClick={handleClass3Btn} className="side-dash-btn">
                Class 3
              </button>
            </List.Item>
            <List.Item>
              {" "}
              <button onClick={handleFirearmsBtn} className="side-dash-btn">
                Firearms
              </button>
            </List.Item>
          </List>
        </Col>
        <Col sm={10}>{renderDash(displayedDash)}</Col>
      </Row>
    </div>
  );
}

export default CatalogDashboard;
