import { Icon } from "semantic-ui-react";
import React from "react";
import ExecutiveData from "./ExecutiveData";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "../header.css";
import logo from "../images/EAC_TopBannerLogo_V01.jpg";

function ExecutiveAccount(props) {
  const id = props.id;
  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <div className="profileMain">
      <Row className="header-banner">
        <Col className="header-col-left">
          <button className="btn-drpdwn" onClick={handleBackButton}>
            <Icon name="arrow left"></Icon> Back
          </button>
        </Col>
        <Col className="header-logo-container">
          <img
            className="header-logo"
            alt="Executive Access Logo"
            src={logo}
            style={{ maxWidth: "300px" }}
          ></img>
        </Col>
        <Col className="header-drpdwn-container"></Col>
      </Row>
      <div>
        <ExecutiveData id={id} />
      </div>
    </div>
  );
}

export default ExecutiveAccount;
