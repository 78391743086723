import React from "react";
import LoginForm from "./LoginForm";
import LoginHeader from "./LoginHeader";
import LoginTextTop from "./LoginTextTop";
import LoginFooter from "./LoginFooter";
import "../App.css";

function LoginPage() {
  return (
    <div className="login-page-container">
      <LoginHeader />
      <LoginTextTop />
      <LoginForm />
      <LoginFooter />
    </div>
  );
}

export default LoginPage;
