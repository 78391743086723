import React from "react";
import MemberSignupForm from "../Storefront/MemberSignupForm";
import { Row, Col } from "react-bootstrap";

function AddNewMember(props) {
  const getAllMembers = () => {};

  return (
    <div>
      <Row className="dash-title-container">
        <Col></Col>
        <Col>
          <h1 className="dash-title">Add Member</h1>
          <p className="dash-title-underline"></p>
        </Col>
        <Col></Col>
      </Row>
      <Row className="dash-content">
        <MemberSignupForm
          clerk={props.adminName}
          store="Executive Account Manager"
          getAllMembers={getAllMembers}
        />
      </Row>
    </div>
  );
}

export default AddNewMember;
