import React, { useState, useEffect } from "react";
import { Button, Icon, Card, Popup, Container, List } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "./AdminAccount.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

function ListItem(props) {
  const navigate = useNavigate();

  let joindate = new Date(props.value.dateJoined)
    .toUTCString()
    .split(" ")
    .slice(1, 4)
    .join(" ");
  let expiredate = new Date(props.value.expiring)
    .toUTCString()
    .split(" ")
    .slice(1, 4)
    .join(" ");

  return (
    <Card className="dash-card">
      <Card.Content>
        <Row columns={3}>
          <h2 className="dash-card-title">
            {props.value.first_name} {props.value.last_name}{" "}
            {props.value.Suffix}
          </h2>
          <Col>
            <Card.Description>
              {" "}
              <span className="dash-card-info-label">
                <Icon name="target" />
              </span>
              {" " + props.value.preferredStore}
            </Card.Description>
            <Card.Description>
              {" "}
              <span className="dash-card-info-label">
                <Icon name="phone" />
              </span>{" "}
              {"(" +
                props.value.phone.slice(0, 3) +
                ") " +
                props.value.phone.slice(3, 6) +
                "-" +
                props.value.phone.slice(6)}
            </Card.Description>
            <Card.Description>
              {" "}
              <span className="dash-card-info-label">
                <Icon name="mail" />
              </span>
              {" " + props.value.email}
            </Card.Description>
          </Col>
          <Col>
            <Card.Description>
              {" "}
              <span className="dash-card-info-label">Member Number:</span>{" "}
              {props.value.number}
            </Card.Description>
            <Card.Description>
              {" "}
              <span className="dash-card-info-label">Card Status:</span>{" "}
              {props.value.card}
            </Card.Description>
            <Card.Description>
              {" "}
              <span className="dash-card-info-label">
                Initial Contact:
              </span>{" "}
              {props.value.acknowledged}
            </Card.Description>
            <Card.Description>
              {" "}
              <span className="dash-card-info-label">Date Joined:</span>{" "}
              {joindate}
            </Card.Description>
            <Card.Description>
              {" "}
              <span className="dash-card-info-label">Date Expiring:</span>{" "}
              {expiredate}
            </Card.Description>
          </Col>
          <Col>
            <Button
              onClick={() => {
                navigate(`/executiveAccount/${props.value.id}`);
              }}
              className="dash-card-btn"
            >
              View Profile
            </Button>
          </Col>
        </Row>
      </Card.Content>
    </Card>
  );
}

function MemberList(props) {
  const memberList = props.memberList;
  const [searchTerm, setSearchTerm] = useState("");
  const memberFeed = Object.keys(memberList)
    .filter((member) => {
      if (searchTerm === "") {
        return member;
      } else if (
        memberList[member].first_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        memberList[member].last_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return member;
      }
      return null;
    })
    .map((mem) => <ListItem key={mem} value={memberList[mem]} />);

  return (
    <div>
      <h2 className="ra-title">
        {props.dashTitle}{" "}
        <span className="title-num">({memberList.length})</span>
      </h2>
      <p className="chat-line"></p>
      <div className="dash-search-container">
        <input
          type="text"
          placeholder="Search First or Last Name"
          className="dash-search"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        ></input>
      </div>
      <Container
        style={{
          overflow: "auto",
          maxHeight: "1000px",
          width: "100%",
        }}
      >
        {memberFeed}
      </Container>
    </div>
  );
}

function MembersDashboard(props) {
  //MEMBER LISTS
  const [allMemberList, setAllMemberList] = useState("");
  const [activeList, setActiveList] = useState("");
  const [expiredMembers, setExpiredMembers] = useState("");
  const [expiringMembers, setExpiringMembers] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [displayedDash, setDisplayedDash] = useState("");
  const renderDash = (displayedDash) => {
    switch (displayedDash) {
      case "activeMembers":
        return (
          <MemberList dashTitle="Active Members" memberList={activeList} />
        );
      case "allMembers":
        return (
          <MemberList dashTitle="All Members" memberList={allMemberList} />
        );
      case "expiringMembers":
        return (
          <MemberList
            dashTitle="Expiring Members"
            memberList={expiringMembers}
          />
        );
      case "expiredMembers":
        return (
          <MemberList dashTitle="Expired Members" memberList={expiredMembers} />
        );
      default:
        return (
          <MemberList dashTitle="All Members" memberList={allMemberList} />
        );
    }
  };

  const handleActiveMemBtn = () => {
    setDisplayedDash("activeMembers");
  };
  const handleAllMemBtn = () => {
    setDisplayedDash("allMembers");
  };
  const handleExpiringMemBtn = () => {
    setDisplayedDash("expiringMembers");
  };
  const handleExpiredMemBtn = () => {
    setDisplayedDash("expiredMembers");
  };

  // GET EXPIRED MEMBERS
  const getExpiredPeople = () => {
    axiosPrivate
      .get("/expiredMembers")
      .then((response) => {
        setExpiredMembers(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //GET EXPIRING MEMBERs
  const getExpiringPeople = () => {
    axiosPrivate
      .get("/expiringMembers")
      .then((response) => {
        setExpiringMembers(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const getAllMembers = () => {
    // GET ALL MEMBERS
    axiosPrivate
      .get("/getMembers")
      .then((response) => {
        setAllMemberList(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //GET ACTIVE ONLY MEMBERS
  const getActiveMembers = () => {
    axiosPrivate
      .get("/activeMembers")
      .then((response) => {
        setActiveList(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  // ********** DOWNLAOD THE MEMBERS LISTS ***********

  const objectToCsv = function (data) {
    const csvRows = [];

    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(","));

    for (const row of data) {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });

      csvRows.push(values.join(","));
    }
    return csvRows.join("\n");
  };

  const download = function (data) {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "download.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const DownloadMembers = async function () {
    try {
      const res = await axiosPrivate("/getMembers");
      const data = res.data;
      const csvData = objectToCsv(data);
      download(csvData);
    } catch (err) {
      console.error(err);
    }
  };

  //***************** useEffect ******************** */

  useEffect(() => {
    getActiveMembers();
    getAllMembers();
    getExpiredPeople();
    getExpiringPeople();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Row className="dash-title-container">
        <Col></Col>
        <Col>
          <h1 className="dash-title">Members</h1>
          <p className="dash-title-underline"></p>
        </Col>
        <Col className="dnl-btn-container">
          <button className="dnl-btn" onClick={DownloadMembers}>
            Download All Members
          </button>
        </Col>
      </Row>
      <Row className="mem-dash-content">
        <Col sm={2} className="side-dash-nav">
          <List>
            <List.Item>
              <Popup
                trigger={
                  <button onClick={handleAllMemBtn} className="side-dash-btn">
                    All Members
                  </button>
                }
                content="List of all members"
                position="right center"
                inverted
              />
            </List.Item>
            <List.Item>
              <Popup
                trigger={
                  <button
                    onClick={handleActiveMemBtn}
                    className="side-dash-btn"
                  >
                    Active Members
                  </button>
                }
                content="List of active members"
                position="right center"
                inverted
              />
            </List.Item>
            <List.Item>
              <Popup
                trigger={
                  <button
                    onClick={handleExpiringMemBtn}
                    className="side-dash-btn"
                  >
                    Expiring Members
                  </button>
                }
                content="Members expiring in 30 days or less"
                position="right center"
                inverted
              />
            </List.Item>
            <List.Item>
              <Popup
                trigger={
                  <button
                    onClick={handleExpiredMemBtn}
                    className="side-dash-btn"
                  >
                    Expired Members
                  </button>
                }
                content="List of expired members"
                position="right center"
                inverted
              />
            </List.Item>
          </List>
        </Col>
        <Col sm={10}>{renderDash(displayedDash)}</Col>
      </Row>
    </div>
  );
}

export default MembersDashboard;
