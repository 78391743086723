import {
  Card,
  Table,
  Icon,
  Modal,
  Button,
  Popup,
  Form,
  Input,
  Divider,
  ModalContent,
  TextArea,
} from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";
import "./ExecutiveAccount.css";
import EditReqForm from "../AdminAccount/RequestEditForm";

function MembershipControls(props) {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  // RENEW MEMBERSHIP
  const card = "Pending";
  const acknowledged = "No";

  //RENEW CUSTOMER SET NEW DATE
  const ChangeRenewal = (id) => {
    axiosPrivate
      .put("/changeRenewal", {
        id: id,
        card: card,
        acknowledged: acknowledged,
      })
      .then((response) => {
        alert("Membership Renewal Completed");
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //DELETE MEMBER
  const deleteMember = (id) => {
    axiosPrivate
      .delete(`/deleteMember/${id}`)
      .then(() => {
        navigate(-1);
        alert("Member Deleted");
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const handleRenew = (id) => {
    if (
      window.confirm("Are you sure you want to renew this membership?") === true
    ) {
      ChangeRenewal(id);
    } else {
      return false;
    }
  };

  const handleDelete = (id) => {
    if (
      window.confirm("Are you sure you want to delete this member?") === true
    ) {
      deleteMember(id);
    } else {
      return false;
    }
  };

  return (
    <div>
      <Divider horizontal>
        <h1 id="mem-controls-title">Membership Controls</h1>
      </Divider>
      <Row style={{ textAlign: "center" }}>
        <Col>
          <Popup
            trigger={
              <Button
                id="exec-delete-mem-btn"
                onClick={() => handleDelete(props.member.id)}
              >
                Delete Account
              </Button>
            }
            position="top center"
            content="Careful! This action can NOT be undone!"
          />
        </Col>
        <Col>
          <Button
            id="exec-renew-btn"
            onClick={() => handleRenew(props.member.id)}
          >
            Renew Membership
          </Button>
        </Col>
      </Row>
    </div>
  );
}

function EditProfileForm(props) {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  //UPDATING MEMBER DETAILS
  const ChangeFirst = (id) => {
    axiosPrivate
      .put("/changeFirst", {
        firstName: formState.firstName,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeMiddle = (id) => {
    axiosPrivate
      .put("/changeMiddle", {
        middleName: formState.middleName,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeLast = (id) => {
    axiosPrivate
      .put("/changeLast", {
        lastName: formState.lastName,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeSuffix = (id) => {
    axiosPrivate
      .put("/changeSuffix", {
        suffix: formState.suffix,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeMemId = (id) => {
    axiosPrivate
      .put("/changeMemId", {
        memId: formState.memberNum,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeEmail = (id) => {
    axiosPrivate
      .put("/changeEmail", {
        email: formState.email,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  //CHANGE USERNAME
  const ChangeUsername = (id) => {
    axiosPrivate
      .put("/changeUsername", {
        username: formState.userName,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  //CHANGE PHONE
  const ChangePhone = (id) => {
    axiosPrivate
      .put("/changePhone", {
        phone: formState.phoneNum,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeAddress = (id) => {
    axiosPrivate
      .put("/changeAddress", {
        address: formState.address,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangePassword = (id) => {
    axiosPrivate
      .put("/changePassword", {
        password: formState.password,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeCard = (id) => {
    axiosPrivate
      .put("/cardStatusChange", {
        card: formState.cardStatus,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeStore = (id) => {
    axiosPrivate
      .put("/changeStore", {
        store: formState.preferredStore,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeCommunication = (id) => {
    axiosPrivate
      .put("/changeCommunication", {
        communication: formState.communication,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  const ChangeMembershipDates = (id) => {
    axiosPrivate
      .put("/updateMembershipDates", {
        joinDate: formState.joinDate,
        renewDate: formState.renewDate,
        expirationDate: formState.expirationDate,
        id: id,
      })
      .then((response) => {
        props.getMemberInfo();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //END UPDATING MEMBER DETAILS

  const [formState, setFormState] = useState({
    firstName: props.member.first_name,
    middleName: props.member.middle_name,
    lastName: props.member.last_name,
    suffix: props.member.Suffix,
    email: props.member.email,
    phoneNum: props.member.phone,
    address: props.member.address,
    memberNum: props.member.number,
    preferredStore: props.member.preferredStore,
    cardStatus: props.member.card,
    communication: props.member.communication,
    userName: props.member.userName,
    password: "",
    joinDate: props.member.dateJoined,
    renewDate: props.member.renewal_date,
    expirationDate: props.member.expiring,
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formState.firstName !== props.member.first_name) {
      ChangeFirst(e.target.id.value);
    } else if (formState.middleName !== props.member.middle_name) {
      ChangeMiddle(e.target.id.value);
    } else if (formState.lastName !== props.member.last_name) {
      ChangeLast(e.target.id.value);
    } else if (formState.suffix !== props.member.Suffix) {
      ChangeSuffix(e.target.id.value);
    } else if (formState.email !== props.member.email) {
      ChangeEmail(e.target.id.value);
    } else if (formState.phoneNum !== props.member.phone) {
      ChangePhone(e.target.id.value);
    } else if (formState.address !== props.member.address) {
      ChangeAddress(e.target.id.value);
    } else if (formState.memberNum !== props.member.number) {
      ChangeMemId(e.target.id.value);
    } else if (formState.preferredStore !== props.member.preferredStore) {
      ChangeStore(e.target.id.value);
    } else if (formState.cardStatus !== props.member.card) {
      ChangeCard(e.target.id.value);
    } else if (formState.communication !== props.member.communication) {
      ChangeCommunication(e.target.id.value);
    } else if (formState.userName !== props.member.userName) {
      ChangeUsername(e.target.id.value);
    } else if (formState.password !== "") {
      ChangePassword(e.target.id.value);
    } else if (formState.joinDate !== props.member.dateJoined) {
      ChangeMembershipDates(e.target.id.value);
    } else if (formState.renewDate !== props.member.renewal_date) {
      ChangeMembershipDates(e.target.id.value);
    } else if (formState.expirationDate !== props.member.expiring) {
      ChangeMembershipDates(e.target.id.value);
    } else {
      alert("Nothing was changed!");
    }
  };

  return (
    <Form className="modal-edit-form" onSubmit={handleSubmit}>
      <Row>
        <Col>
          <div className="modal-edit-form-section">
            <Divider horizontal>Account Info</Divider>
            <Form.Field inline width={16}>
              <label>First Name</label>
              <Input
                name="firstName"
                onChange={handleChange}
                defaultValue={formState.firstName}
              />
            </Form.Field>
            <Form.Field inline width={16}>
              <label>Middle Name</label>
              <Input
                name="middleName"
                onChange={handleChange}
                defaultValue={formState.middleName}
              />
            </Form.Field>
            <Form.Field inline width={16}>
              <label>Last Name</label>
              <Input
                name="lastName"
                onChange={handleChange}
                defaultValue={formState.lastName}
              />
            </Form.Field>
            <Form.Field>
              <label>Suffix</label>
              <select
                onChange={handleChange}
                name="suffix"
                value={formState.suffix}
              >
                <option value="">No suffix</option>
                <option value="JR">Jr</option>
                <option value="SR">Sr</option>
                <option value="II">II</option>
                <option value="III">III</option>
              </select>
            </Form.Field>
            <Form.Field inline width={16}>
              <label>Email</label>
              <Input
                name="email"
                type="email"
                onChange={handleChange}
                defaultValue={formState.email}
              />
            </Form.Field>
            <Form.Field inline width={16}>
              <label>Phone #</label>
              <Input
                name="phoneNum"
                type="tel"
                pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
                onChange={handleChange}
                defaultValue={formState.phoneNum}
              />
            </Form.Field>
            <Form.Field inline width={16}>
              <label>Address</label>
              <Input
                name="address"
                onChange={handleChange}
                defaultValue={formState.address}
              />
            </Form.Field>
          </div>
          <div className="modal-edit-form-section">
            <Divider horizontal>Login Info</Divider>
            <Form.Field inline width={16}>
              <label>Username</label>
              <Input
                name="userName"
                onChange={handleChange}
                defaultValue={formState.userName}
              />
            </Form.Field>
            <Form.Field inline width={16}>
              <label>Password</label>
              <Input
                name="password"
                type="password"
                onChange={handleChange}
                placeholder={"*********"}
              />
            </Form.Field>
          </div>
        </Col>
        <Col>
          <div className="modal-edit-form-section">
            <Divider horizontal>Membership Info</Divider>
            <Form.Field inline width={16}>
              <label>Member Number</label>
              <Input
                name="memberNum"
                type="number"
                min="0"
                onChange={handleChange}
                defaultValue={formState.memberNum}
              ></Input>
            </Form.Field>
            <Form.Field width={16}>
              <label>Preferred Store</label>
              <select
                onChange={handleChange}
                name="preferredStore"
                value={formState.preferredStore}
              >
                <option value="Apopka">Apopka</option>
                <option value="Casselberry">Casselberry</option>
                <option value="Clearwater">Clearwater</option>
                <option value="Fort Lauderdale">Fort Lauderdale</option>
                <option value="Fort Myers">Fort Myers</option>
                <option value="Lakeland">Lakeland</option>
                <option value="Sarasota">Sarasota</option>
                <option value="Tampa">Tampa</option>
                <option value="West Palm Beach">West Palm Beach</option>
              </select>
            </Form.Field>
            <Form.Field width={16}>
              <label>Card Status</label>
              <select
                onChange={handleChange}
                name="cardStatus"
                value={formState.cardStatus}
              >
                <option value="Yes Card">Yes Card</option>
                <option value="Pending">Pending</option>
              </select>
            </Form.Field>
            <Form.Field width={16}>
              <label>Communication</label>
              <select
                onChange={handleChange}
                name="communication"
                value={formState.communication}
              >
                <option value="Email only">Email only</option>
                <option value="Phone only">Phone only</option>
                <option value="Both">Both</option>
              </select>
            </Form.Field>
            <Form.Field inline width={16}>
              <label>Join Date</label>
              <Input
                name="joinDate"
                type="date"
                defaultValue={formState.joinDate}
                onChange={handleChange}
              ></Input>
            </Form.Field>
            <Form.Field inline width={16}>
              <label>Renewal Date</label>
              <Input
                name="renewDate"
                type="date"
                defaultValue={formState.renewDate}
                onChange={handleChange}
              ></Input>
            </Form.Field>
            <Form.Field inline width={16}>
              <label>Expiration Date</label>
              <Input
                name="expirationDate"
                type="date"
                defaultValue={formState.expirationDate}
                onChange={handleChange}
              ></Input>
            </Form.Field>
          </div>
        </Col>
        <div style={{ textAlign: "center" }}>
          <Form.Field width={16}>
            <Popup
              trigger={<Button content="Submit"></Button>}
              position="top center"
              content="Clicking submit will update the information for any fields you have changed above."
            ></Popup>
          </Form.Field>
        </div>
        <input
          style={{ display: "none" }}
          name="id"
          value={props.member.id}
          readOnly
        />
      </Row>
    </Form>
  );
}

function AdminSpecialRequestForm(props) {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const userId = props.member.id;
  const memberName =
    props.member.first_name +
    " " +
    props.member.last_name +
    " " +
    props.member.Suffix;
  const [formState, setFormState] = useState({
    category: "",
    brand: "",
    item: "",
    upcsku: "",
    quantity: "",
    status: "PENDING",
    note: "",
    source: "",
  });

  const submitRequest = () => {
    axiosPrivate
      .post("/specialRequest", {
        category: formState.category,
        item: formState.item,
        brand: formState.brand,
        quantity: formState.quantity,
        status: formState.status,
        userId: userId,
        sku: formState.upcsku,
        note: formState.note,
        source: formState.source,
        memberName: memberName,
      })
      .then(() => {
        props.getMemberRequests();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitRequest();
    e.target.reset();
  };

  return (
    <Form className="modal-edit-form" onSubmit={handleSubmit}>
      <Form.Field width={16}>
        <label>Category</label>
        <select
          onChange={handleChange}
          required
          name="category"
          value={formState.category}
        >
          <option value="">Select a Category</option>
          <option value="ACCESSORIES">Accessories</option>
          <option value="AMMUNITION">Ammunition</option>
          <option value="CLASS3">Class 3</option>
          <option value="FIREARMS">Firearms</option>
        </select>
      </Form.Field>
      <Form.Field width={16} inline>
        <label>Brand</label>
        <Input name="brand" required onChange={handleChange} />
      </Form.Field>
      <Form.Field width={16} inline>
        <label>Item</label>
        <Input name="item" required onChange={handleChange} />
      </Form.Field>
      <Form.Field width={16} inline>
        <label>UPC / SKU</label>
        <Input name="upcsku" required onChange={handleChange} />
      </Form.Field>
      <Form.Field width={16} inline>
        <label>Quantity</label>
        <Input
          name="quantity"
          type="number"
          min="0"
          required
          onChange={handleChange}
        />
      </Form.Field>
      <Form.Field width={16} inline>
        <label>Source</label>
        <Input name="source" onChange={handleChange} />
      </Form.Field>
      <Form.Field width={16} inline>
        <label>Note</label>
        <TextArea name="note" onChange={handleChange}></TextArea>
      </Form.Field>
      <Form.Field width={16} style={{ textAlign: "center" }}>
        <Button content="Submit"></Button>
      </Form.Field>
    </Form>
  );
}

function RequestItem(props) {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [newNote, setNewNote] = useState("");
  const [newStatus, setNewStatus] = useState("");

  let dateCreated = new Date(props.value.date_created)
    .toUTCString()
    .split(" ")
    .slice(0, 4)
    .join(" ");
  let dateUpdated = new Date(props.value.date_updated)
    .toUTCString()
    .split(" ")
    .slice(0, 4)
    .join(" ");

  const updateStatus = (id) => {
    axiosPrivate
      .put("/statusUpdate", {
        status: newStatus,
        id: id,
      })
      .then((response) => {
        props.getMemberRequests();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //UPDATE REQUEST NOTE
  const updateNote = (id) => {
    axiosPrivate
      .put("/noteUpdate", {
        note: newNote,
        id: id,
      })
      .then((response) => {
        props.getMemberRequests();
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //   //Function has not been created in the API yet
  //   const handleDelete = (id) => {
  //     if(
  //       window.confirm("Are you sure you want to delete this request?") === true
  //     ){
  //       deleteRequest(id); // function does not exist in API yet
  //     } else{
  //       return false;
  //     }
  //   }

  //   //Function has not been created in API yet
  //   const deleteRequest = id => {
  //     axiosPrivate.delete(`/deleteRequest/${id}`).then(() => props.getMemberRequests()).catch((error) => {
  //         console.error(error);
  //         navigate("/");
  //       })
  //   };

  return (
    <Table.Row>
      <Table.Cell>{props.value.category}</Table.Cell>
      <Table.Cell>{props.value.brand}</Table.Cell>
      <Table.Cell>{props.value.item}</Table.Cell>
      <Table.Cell>{props.value.sku}</Table.Cell>
      <Table.Cell>{props.value.quantity}</Table.Cell>
      <Table.Cell>{props.value.price}</Table.Cell>
      <Table.Cell>{props.value.source}</Table.Cell>
      <Table.Cell>{props.value.note}</Table.Cell>
      <Table.Cell>{dateCreated}</Table.Cell>
      <Table.Cell>{dateUpdated}</Table.Cell>
      <Table.Cell>
        {props.value.status}
        <br />
        <select
          onChange={(e) => {
            setNewStatus(e.target.value.toUpperCase());
          }}
        >
          <option>Choose Status</option>
          <option value="PENDING">Pending</option>
          <option value="ON HOLD">On Hold</option>
          <option value="BACK ORDER">Back Order</option>
          <option value="ORDERED">Ordered</option>
          <option value="COMPLETED">Completed</option>
          <option value="CANCELED">Canceled</option>
          <option value="OFFERED">Offered</option>
        </select>
        <br />
        <button
          className="dnl-btn"
          style={{ minWidth: "100px", marginTop: "1em" }}
          onClick={() => {
            updateStatus(props.value.id);
          }}
        >
          Update
        </button>
      </Table.Cell>
      <Table.Cell>
        <Modal
          style={{
            height: "75%",
            width: "600px",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "0px",
          }}
          className="item-modal-market"
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open[props.value]}
          trigger={<button className="dnl-btn">Edit Request Details</button>}
        >
          <Modal.Header>
            Edit Request Details
            <p className="modal-header-underline"></p>
          </Modal.Header>
          <Modal.Content>
            <EditReqForm
              value={props.value}
              refreshData={props.getMemberRequests}
            />
          </Modal.Content>
        </Modal>
      </Table.Cell>
    </Table.Row>
  );
}

function RequestItemFeed(props) {
  const requestList = props.requestList;
  const requestFeed = Object.keys(requestList).map((request) => (
    <RequestItem
      key={request}
      value={requestList[request]}
      getMemberRequests={props.getMemberRequests}
    />
  ));

  return (
    <Table
      celled
      striped
      color="red"
      className="exec-acct-data-table"
      style={{ textAlign: "center", width: "100%" }}
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell>Brand</Table.HeaderCell>
          <Table.HeaderCell>Item</Table.HeaderCell>
          <Table.HeaderCell>UPC / SKU</Table.HeaderCell>
          <Table.HeaderCell>Quantity</Table.HeaderCell>
          <Table.HeaderCell>Price</Table.HeaderCell>
          <Table.HeaderCell>Source</Table.HeaderCell>
          <Table.HeaderCell>Note</Table.HeaderCell>
          <Table.HeaderCell>Date Created</Table.HeaderCell>
          <Table.HeaderCell>Date Updated</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{requestFeed}</Table.Body>
    </Table>
  );
}

function ExecutiveData(props) {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const [memberDetails, setMemberDetails] = useState("");
  const [requestList, setRequestList] = useState("");
  const [open, setOpen] = React.useState(false);

  const { id } = useParams();

  const getMemberInfo = () => {
    axiosPrivate
      .get("/getSingleMem", {
        params: { id: id },
      })
      .then((response) => {
        const result = response.data;
        setMemberDetails(result[0]);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //GETTING SPECIFIC MEMBER REQUESTS
  const getMemberRequests = () => {
    axiosPrivate
      .get("/getMemberRequests", {
        params: { id: id },
      })
      .then((response) => {
        const result = response.data;
        setRequestList(result);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  // ***** END FUNCTIONS****
  // START USE EFFECT
  useEffect(() => {
    getMemberInfo();
    getMemberRequests();
  }, []);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div className="exec-data-card-margin exec-data-flex-card">
          <Card fluid className="exec-acct-card">
            <Card.Content>
              <Card.Header className="exec-acct-card-header">
                Account Info
              </Card.Header>
            </Card.Content>
            <Card.Content style={{ textAlign: "center" }}>
              <h2>
                {memberDetails.first_name} {memberDetails.middle_name}{" "}
                {memberDetails.last_name} {memberDetails.Suffix}
              </h2>
              <Card.Meta>{memberDetails.address} </Card.Meta>
            </Card.Content>
            <Card.Content style={{ textAlign: "center", display: "flex" }}>
              <div style={{ width: "50%" }}>
                <Icon name="phone" />
                {"(" +
                  memberDetails.phone?.slice(0, 3) +
                  ") " +
                  memberDetails.phone?.slice(3, 6) +
                  "-" +
                  memberDetails.phone?.slice(6)}
              </div>
              <div style={{ width: "50%" }}>
                {" "}
                <Icon name="mail" />
                {memberDetails.email}
              </div>
            </Card.Content>
            <Card.Content>
              <Table
                celled
                striped
                color="red"
                className="exec-acct-data-table"
              >
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="3">
                      Member Info
                      <Card.Meta>
                        Member Number: {memberDetails.number}{" "}
                      </Card.Meta>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell className="exec-data-tbl-label">
                      <Icon name="calendar check outline" />
                      Join Date
                    </Table.Cell>
                    <Table.Cell>{memberDetails.dateJoined}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className="exec-data-tbl-label">
                      <Icon name="calendar check outline" />
                      Renewal Date
                    </Table.Cell>
                    <Table.Cell>{memberDetails.renewal_date}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className="exec-data-tbl-label">
                      <Icon name="calendar times outline" />
                      Expiring Date
                    </Table.Cell>
                    <Table.Cell>{memberDetails.expiring}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className="exec-data-tbl-label">
                      <Icon name="building outline" />
                      Preferred Store
                    </Table.Cell>
                    <Table.Cell>{memberDetails.preferredStore}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className="exec-data-tbl-label">
                      <Icon name="id card outline" />
                      Card Status
                    </Table.Cell>
                    <Table.Cell>{memberDetails.card}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell className="exec-data-tbl-label">
                      <Icon name="handshake outline" />
                      Communication Method
                    </Table.Cell>
                    <Table.Cell>{memberDetails.communication}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card.Content>
            <Card.Content style={{ textAlign: "center" }}>
              <Modal
                style={{
                  height: "90%",
                  width: "75%",
                  position: "fixed",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%, -50%)",
                  borderRadius: "0px",
                }}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={<button className="dnl-btn">Edit Profile</button>}
                className="item-modal-market"
              >
                <Modal.Actions>
                  <Button icon="x" onClick={() => setOpen(false)} />
                </Modal.Actions>
                <Modal.Header>
                  Edit Profile Information
                  <p className="modal-header-underline"></p>
                </Modal.Header>
                <ModalContent>
                  <EditProfileForm
                    member={memberDetails}
                    getMemberInfo={getMemberInfo}
                  />
                  <MembershipControls
                    member={memberDetails}
                    getMemberInfo={getMemberInfo}
                  />
                </ModalContent>
              </Modal>
            </Card.Content>
          </Card>
        </div>
        <div className="exec-data-card-margin exec-data-flex-card">
          <Card fluid className="exec-acct-card" style={{ height: "100%" }}>
            <Card.Content>
              <Card.Header className="exec-acct-card-header">
                Special Request Form
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <AdminSpecialRequestForm
                member={memberDetails}
                getMemberRequests={getMemberRequests}
              />
            </Card.Content>
          </Card>
        </div>
      </div>
      {/*  START REQUESTS */}
      <div className="exec-data-card-margin">
        <Card fluid className="exec-acct-card">
          <Card.Content>
            <Card.Header className="exec-acct-card-header">
              Requests
            </Card.Header>
          </Card.Content>
          <Card.Content style={{ overflowY: "scroll", height: "100%" }}>
            <RequestItemFeed
              requestList={requestList}
              getMemberRequests={getMemberRequests}
            />
          </Card.Content>
        </Card>
      </div>
    </div>
  );
}

export default ExecutiveData;
