import React, { useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Popup } from "semantic-ui-react";
import emailjs from "@emailjs/browser";
import "./SpecialRequestForm.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

function SpeciaRequestForm(props) {
  const userID = props.loggedInMember.id;
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const memberName =
    props.loggedInMember.first_name + " " + props.loggedInMember.last_name + " " + props.loggedInMember.Suffix;
  const form = useRef();

  const [formState, setFormState] = useState({
    request_category: "",
    request_model: "",
    request_brand: "",
    request_status: "PENDING",
    request_sku: "",
  });

  const submitRequest = () => {
    axiosPrivate
      .post("/specialRequest", {
        category: formState.request_category,
        item: formState.request_model,
        brand: formState.request_brand,
        sku: formState.request_sku,
        status: "PENDING",
        userId: userID,
        memberName: memberName,
        quantity: 1,
      })
      .then((response) => {
        if (response.status === 200) {
          props.getMemberRequests();
          alert("Your request was submitted!");
        }
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const sendReqEmail = () => {
    emailjs
      .sendForm(
        "service_640rs57",
        "template_nvhp8i6",
        form.current,
        "user_QGlVs4Qz8yzIHPSfomOw6"
      )
      .then(
        (result) => {},
        (error) => {}
      )
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const valueCap = value.toUpperCase();
    const name = e.target.name;
    setFormState({
      ...formState,
      [name]: valueCap,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitRequest();
    sendReqEmail();
    e.target.reset();
  };

  return (
    <form ref={form} onSubmit={handleSubmit} className="req-form">
      <Row>
        <Col>
          <label htmlFor="" className="req-form-label">
            Category:
          </label>
        </Col>
        <Col>
          <select
            required
            name="request_category"
            value={formState.category}
            onChange={handleChange}
            className="req-form-select"
          >
            <option value="">-- Select a category --</option>
            <option value="FIREARMS">FIREARMS</option>
            <option value="CLASS 3">CLASS 3</option>
            <option value="AMMUNITION">AMMUNITION</option>
            <option value="ACCESSORIES">ACCESSORIES</option>
          </select>
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="request_brand" className="req-form-label">
            Brand:
          </label>
        </Col>
        <Col>
          <input
            required
            name="request_brand"
            className="req-form-input"
            type="text"
            placeholder="ex. Smith &amp; Wesson or SilencerCo"
            onChange={handleChange}
          ></input>
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="request-model" className="req-form-label">
            Model:
          </label>
        </Col>
        <Col>
          <input
            required
            name="request_model"
            className="req-form-input"
            type="text"
            placeholder="ex. M&amp;P Shield Plus or Osprey 2.0"
            onChange={handleChange}
          ></input>
        </Col>
      </Row>
      <Row>
        <Col>
          <label htmlFor="request-sku" className="req-form-label">
            SKU/UPC:
          </label>
        </Col>
        <Col>
          <Popup
            trigger={
              <input
                name="request_sku"
                className="req-form-input"
                type="text"
                onChange={handleChange}
              ></input>
            }
            content="You can usually find the item SKU or UPC on the manufaturer's website.
            If you cannot find the SKU or UPC, please leave the field blank."
            position="top center"
          ></Popup>
        </Col>
      </Row>
      <Row>
        <Col className="req-submit-container">
          <input
            className="req-form-submit"
            type="submit"
            value="Submit Request"
          ></input>
        </Col>
      </Row>
      <input
        name="member_name"
        type="text"
        value={memberName}
        style={{ display: "none" }}
        readOnly
      ></input>
    </form>
  );
}

export default SpeciaRequestForm;
