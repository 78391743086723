import React, { useState } from "react";
import {
  Input,
  Button,
  Form,
} from "semantic-ui-react";
import { Row } from "react-bootstrap";
import "./RequestDashboard.css";
import "./AdminAccount.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

function EditReqForm(props) {
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    //UPDATE REQUEST
    const updateRequest = (id) => {
        axiosPrivate
        .put("/updateRequest", {
            id: id,
            category: formState.category,
            brand: formState.brand,
            item: formState.item,
            sku: formState.sku,
            quantity: formState.quantity,
            source: formState.source,
            note: formState.note,
        })
        .then((response) => {
            if (response.status === 200) {
                props.refreshData();
            }
        })
        .catch((error) => {
            console.error(error);
            navigate("/");
        })
    }
  
    const [formState, setFormState] = useState({
      category: props.value.category,
      brand: props.value.brand,
      item: props.value.item,
      sku: props.value.sku,
      quantity: props.value.quantity,
      source: props.value.source,
      note: props.value.note,
    });
  
    const handleChange = (e) => {
      const value = e.target.value;
      const name = e.target.name;
      setFormState({
        ...formState,
        [name]: value,
      });
    };
  
    const handleSubmit = (e) => {
      if (formState.category !== props.value.category) {
        updateRequest(e.target.id.value);
      }  else if (formState.brand !== props.value.brand) {
        updateRequest(e.target.id.value);
      } else if (formState.item !== props.value.item) {
        updateRequest(e.target.id.value);
      } else if (formState.sku !== props.value.sku) {
        updateRequest(e.target.id.value);
      } else if (formState.quantity !== props.value.quantity) {
        updateRequest(e.target.id.value);   
      } else if (formState.source !== props.value.source) {
        updateRequest(e.target.id.value);
      } else if (formState.note !== props.value.note) {
        updateRequest(e.target.id.value);
      } else {
        alert("Nothing was changed!");
      }
    };
  
    return (
      <Form className="item-form-market" onSubmit={handleSubmit}>
        <Row>
          <Form.Field width={16}>
            <label>Category</label>
            <select
              onChange={handleChange}
              name="category"
              value={formState.category}
            >
              <option value="ACCESSORIES">Accessories</option>
              <option value="AMMUNITION">Ammunition</option>
              <option value="CLASS3">Class 3</option>
              <option value="FIREARMS">Firearms</option>
            </select>
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Brand</label>
            <Input
              onChange={handleChange}
              name="brand"
              defaultValue={formState.brand}
            ></Input>
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Item</label>
            <Input
              onChange={handleChange}
              name="item"
              defaultValue={formState.item}
            />
          </Form.Field>
          <Form.Field width={16} inline>
            <item>SKU</item>
            <Input
              onChange={handleChange}
              name="sku"
              defaultValue={formState.sku}
            />
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Quantity</label>
            <Input
              onChange={handleChange}
              name="quantity"
              defaultValue={formState.quantity}
            />
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Source</label>
            <Input
              onChange={handleChange}
              name="source"
              defaultValue={formState.source}
            />
          </Form.Field>
          <Form.Field width={16} inline>
            <label>Note</label>
            <Input
              onChange={handleChange}
              name="note"
              defaultValue={formState.note}
            />
          </Form.Field>
          <input
            style={{ display: "none" }}
            name="id"
            value={props.value.id}
            readOnly
          />
        </Row>
        <Row>
          <Form.Field>
            <Button content="Submit"></Button>
          </Form.Field>
        </Row>
      </Form>
    );
  }
  export default EditReqForm;