import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Card,
  Table,
  Modal,
  Form,
  Divider,
  Popup,
} from "semantic-ui-react";
import { Row, Col } from "react-bootstrap";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

function AddAdminForm(props) {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [formState, setFormState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    role: "",
    store: "",
    userName: "",
    password: "",
  });

  const register = () => {
    axiosPrivate
      .post("/registerAdmin", {
        firstName: formState.firstName,
        lastName: formState.lastName,
        email: formState.email,
        phoneNumber: formState.phoneNumber,
        role: formState.role,
        store: formState.store,
        userName: formState.userName,
        password: formState.password,
      })
      .then((response) => {
        props.getAdmins();
        alert("Submission Successful");
      })
      .catch((error) => {
        //server returns HTTP error code 422 if a record already exists in the database
        if (error.response.status === 422) {
          alert(error.response.data.message);
        } else {
          console.error(error);
          navigate("/");
        }
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    register();
    e.target.reset();
  };

  return (
    <Form
      onSubmit={handleSubmit}
      className="modal-edit-form"
      style={{
        width: "85%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "1em",
        padding: "2em 1em 1em",
        border: "2px solid black",
      }}
    >
      <Form.Group widths="equal">
        <Form.Field inline>
          <label>First Name</label>
          <Input fluid required name="firstName" onChange={handleChange} />
        </Form.Field>
        <Form.Field inline>
          <label>Last Name</label>
          <Input fluid required name="lastName" onChange={handleChange} />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field inline>
          <label>Email</label>
          <Input
            fluid
            required
            name="email"
            type="email"
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field inline>
          <label>Phone Number</label>
          <Input
            fluid
            required
            name="phoneNumber"
            type="tel"
            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field inline>
          <label>Username</label>
          <Input fluid required name="userName" onChange={handleChange} />
        </Form.Field>
        <Form.Field inline>
          <label>Password</label>
          <Input
            fluid
            required
            name="password"
            type="password"
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Role</label>
          <select
            onChange={handleChange}
            required
            name="role"
            value={formState.role}
          >
            <option value="">Choose Role</option>
            <option value="manager">Manager</option>
            <option value="admin">Admin</option>
          </select>
        </Form.Field>
        <Form.Field>
          <label>Store</label>
          <select
            onChange={handleChange}
            required
            name="store"
            value={formState.store}
          >
            <option value="">Choose Store</option>
            <option value="APOPKA">Apopka</option>
            <option value="CASSELBERRY">Casselberry</option>
            <option value="CLEARWATER">Clearwater</option>
            <option value="FORT LAUDERDALE">Fort Lauderdale</option>
            <option value="FORT MYERS">Fort Myers</option>
            <option value="LAKELAND">Lakeland</option>
            <option value="SARASOTA">Sarasota</option>
            <option value="TAMPA">Tampa</option>
            <option value="WEST PALM BEACH">West Palm Beach</option>
            <option value="ALL">All</option>
          </select>
        </Form.Field>
      </Form.Group>
      <Form.Field style={{ textAlign: "center" }}>
        <Button content="Register Admin" />
      </Form.Field>
    </Form>
  );
}

function EditAdminForm(props) {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  //CHANGE USERNAME
  const changeUsername = (id) => {
    axiosPrivate
      .put("/changeUsername", {
        username: formState.userName,
        id: id,
      })
      .then((response) => {
        alert("User Name successfully updated");
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //CHANGE PASSWORD
  const changePassword = (id) => {
    axiosPrivate
      .put("/changePassword", {
        password: formState.password,
        id: id,
      })
      .then((response) => {
        alert("Password successfully updated");
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const [formState, setFormState] = useState({
    userName: props.value.userName,
    password: "",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formState.userName !== props.value.userName) {
      changeUsername(e.target.id.value);
    } else if (formState.password !== "") {
      changePassword(e.target.id.value);
    } else {
      alert("Nothing was changed!");
    }
  };

  return (
    <Form className="modal-edit-form" onSubmit={handleSubmit}>
      <div className="modal-edit-form-section">
        <Divider horizontal>Login Info</Divider>
        <Form.Field inline width={16}>
          <label>Username</label>
          <Input
            name="userName"
            onChange={handleChange}
            defaultValue={formState.userName}
          />
        </Form.Field>
        <Form.Field inline width={16}>
          <label>Password</label>
          <Input
            name="password"
            type="password"
            onChange={handleChange}
            placeholder="**********"
          />
        </Form.Field>
      </div>
      <div style={{ textAlign: "center" }}>
        <Form.Field width={16}>
          <Popup
            trigger={<Button content="Submit"></Button>}
            position="top center"
            content="Clicking submit will update the information for any fields you have changed above."
          ></Popup>
        </Form.Field>
      </div>
      <input
        style={{ display: "none" }}
        name="id"
        value={props.value.id}
        readOnly
      />
    </Form>
  );
}

function ListItem(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <Table.Row>
      <Table.Cell>
        <Modal
          style={{
            height: "90%",
            width: "75%",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "0px",
          }}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open[props.value]}
          trigger={
            <Button className="member-info-btn-storefront" content="View" />
          }
          className="item-modal-market"
        >
          <Modal.Header>
            Edit Information
            <p className="modal-header-underline"></p>
          </Modal.Header>
          <Modal.Content>
            <EditAdminForm value={props.value} />
          </Modal.Content>
        </Modal>
      </Table.Cell>
      <Table.Cell>
        {props.value.first_name} {props.value.last_name}{" "}
      </Table.Cell>
      <Table.Cell> {props.value.role} </Table.Cell>
      <Table.Cell> {props.value.preferredStore} </Table.Cell>
    </Table.Row>
  );
}

function AdminList(props) {
  const adminList = props.listData;
  const adminFeed = Object.keys(adminList).map((admin) => (
    <ListItem key={admin} value={adminList[admin]} />
  ));

  return <Table.Body>{adminFeed}</Table.Body>;
}

function AdminCardScrollBox(props) {
  return (
    <Card
      className="storefront-search-results"
      style={{ margin: "auto", width: "98%" }}
    >
      <Card.Header>
        <h1>{props.cardTitle}</h1>
      </Card.Header>
      <Card.Content style={{ overflowY: "scroll", height: "300px" }}>
        <Table celled striped color="red">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Options</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Role</Table.HeaderCell>
              <Table.HeaderCell>Location</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <AdminList listData={props.listData} />
        </Table>
      </Card.Content>
    </Card>
  );
}

function AddAdminDashboard() {
  const [admins, setAdmins] = useState("");
  const [managers, setManagers] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const getAdmins = () => {
    axiosPrivate
      .get("/getAdmins", {})
      .then((response, error) => {
        setAdmins(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const getManagers = () => {
    axiosPrivate
      .get("/getManagers", {})
      .then((response, error) => {
        setManagers(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  useEffect(() => {
    getAdmins();
    getManagers();
  }, []);

  return (
    <div>
      <Row className="dash-title-container">
        <Col></Col>
        <Col>
          <h1 className="dash-title">Register Admin</h1>
          <p className="dash-title-underline"></p>
        </Col>
        <Col></Col>
      </Row>
      <div className="dash-content">
        <Row>
          <AddAdminForm getAdmins={getAdmins} />
        </Row>
        <Row style={{ marginTop: "2em" }}>
          <Col>
            <AdminCardScrollBox cardTitle="Admins" listData={admins} />
          </Col>
          <Col>
            <AdminCardScrollBox cardTitle="Managers" listData={managers} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AddAdminDashboard;
