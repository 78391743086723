import React, { useState } from "react";
import { Card, Table } from "semantic-ui-react";

import { Row } from "react-bootstrap";
import "./addMember.css";
import "./Storefront.css";
import StorefrontViewMember from "./StorefrontViewMember";

function ListItem(props) {
  let joindate1 = new Date(props.value.dateJoined)
    .toUTCString()
    .split(" ")
    .slice(0, 4)
    .join(" ");
  let expiring = new Date(props.value.expiring)
    .toUTCString()
    .split(" ")
    .slice(0, 4)
    .join(" ");

  return (
    <Table.Row style={{ overflowY: "scroll", width: "100%" }}>
      <Table.Cell>
        {" "}
        <StorefrontViewMember
          value={props.value}
          getAllMembers={props.getAllMembers}
          joinDate={joindate1}
          expiring={expiring}
        />
      </Table.Cell>
      <Table.Cell>{props.value.first_name}</Table.Cell>
      <Table.Cell> {props.value.last_name}{" "}{props.value.Suffix}</Table.Cell>
      <Table.Cell>{joindate1}</Table.Cell>
      <Table.Cell>{expiring}</Table.Cell>
    </Table.Row>
  );
}

function CustomerList(props) {
  const memberList = props.memberList;
  const searchTerm = props.searchTerm;

  const customerFeed = Object.keys(memberList)
    .filter((member) => {
      if (searchTerm === "") {
        return "";
      } else if (
        memberList[member].first_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        memberList[member].last_name
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      ) {
        return member;
      }
      return null;
    })
    .map((mem) => (
      <ListItem
        key={mem}
        value={memberList[mem]}
        getAllMembers={props.getAllMembers}
      />
    ));

  return customerFeed;
}

function StorefrontSearch(props) {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <Row className="searchMemberStorefront">
      {/* START CUSTOMER LOOK UP */}
      <h1 className="storefront-section-title">Customer Look Up</h1>
      <p className="underline"></p>
      <div className="search-box-storefront-container">
        <input
          className="search-box-storefront"
          type="text"
          placeholder="Search First or Last Name"
          onChange={(event) => {
            setSearchTerm(event.target.value);
          }}
        ></input>
      </div>
      <Card
        className="storefront-search-results"
        style={{
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "1em",
        }}
      >
        <Card.Content
          style={{
            overflowY: "scroll",
            height: "300px",
          }}
        >
          <Table celled striped color="red">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Details</Table.HeaderCell>
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Date Joined</Table.HeaderCell>
                <Table.HeaderCell>Date Expiring</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <CustomerList
                memberList={props.memberList}
                getAllMembers={props.getAllMembers}
                searchTerm={searchTerm}
              />
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    </Row>
  );
}

export default StorefrontSearch;
