import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./LogIn/LoginPage";
import AdminAccount from "./AdminAccount/AdminAccount";
import ExecutiveAccount from "./ExecutiveAccount/ExecutiveAccount";
import StoreFront from "./Storefront/Storefront";
import MemberProfile from "./MemberProfile/Profilepage";
import React from "react";
import { AuthProvider } from "./context/AuthProvider";
import { RequireAuth } from "./RequireAuth";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" exact element={<LoginPage />} />
        {/* PAGE ROUTES */}
        <Route
          path="/executiveAccount/:id"
          exact
          element={
            <RequireAuth>
              <ExecutiveAccount />
            </RequireAuth>
          }
        />
        <Route
          path="/adminAccount/:id"
          exact
          element={
            <RequireAuth>
              <AdminAccount />
            </RequireAuth>
          }
        />
        <Route
          path="/storeFront/:id"
          element={
            <RequireAuth>
              <StoreFront />
            </RequireAuth>
          }
        />
        <Route
          path="/profile/:id"
          exact
          element={
            <RequireAuth>
              <MemberProfile />
            </RequireAuth>
          }
        />
      </Routes>
    </AuthProvider>
  );
}

export default App;
