import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "semantic-ui-react";
import { Row, Col, Container } from "react-bootstrap";
import TempPassForm from "./TempPassForm";
import { useAuth } from "../context/AuthProvider";
import axios from "../api/axios";

function LoginForm() {
  const [loginStatus, setLoginStatus] = useState("");
  const form = useRef();

  const auth = useAuth();
  const navigate = useNavigate();

  const [formState, setFormState] = useState({
    userName: "",
    password: "",
  });

  const login = () => {
    axios
      .post("/memberLogin", {
        userName: formState.userName,
        password: formState.password,
      })
      .then((response) => {
        if (response.data[0].role === "admin") {
          const userData = response.data[0];
          auth.login(userData);
          navigate(`AdminAccount/${userData.id}`);
        } else if (response.data[0].role === "manager") {
          const userData = response.data[0];
          auth.login(userData);
          navigate(`/storeFront/${userData.id}`);
        } else if (response.data[0].role === "member") {
          const userData = response.data[0];
          auth.login(userData);
          navigate(`/profile/${userData.id}`);
        } else {
          setLoginStatus(
            <p className="loginStatus">
              Incorrect Username/Password Combination
            </p>
          );
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setLoginStatus(
            <p className="incorrect-combo">
              Incorrect Username/Password Combination
            </p>
          );
        } else if (error.response.status === 403) {
          setLoginStatus(
            <p className="incorrect-combo">{error.response.data.message}</p>
          );
        }
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  return (
    <div className="LoginContainer">
      <Container>
        <Row>
          <Col className="BenCol4">
            <form ref={form} onSubmit={handleSubmit}>
              <p className="login-title">User Login</p>
              <p className="login-underline"></p>
              <input
                className="loginInput"
                type="text"
                placeholder="User Name"
                autoComplete="username"
                name="userName"
                onChange={handleChange}
              ></input>
              <br></br>
              <input
                className="loginInput"
                type="password"
                placeholder="Password"
                autoComplete="current-password"
                name="password"
                onChange={handleChange}
              ></input>
              <br></br>
              <input className="validate" type="submit" value="Sign In" />
            </form>
            <br></br>
            <TempPassForm />
          </Col>
        </Row>
        <Row>
          <Col>
            <Header icon>{loginStatus}</Header>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default LoginForm;
