import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import "../header.css";
import logo from "../images/EAC_TopBannerLogo_V01.jpg";
import { Icon, Modal, Button } from "semantic-ui-react";
import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import axios from "../api/axios";

function AdminHeader(props) {
  const [open, setOpen] = React.useState(false);
  const form = useRef();
  const auth = useAuth();
  const navigate = useNavigate();

  //SEND ISSUE EMAIL
  const sendIssueEmail = () => {
    emailjs
      .sendForm(
        "service_640rs57",
        "template_95cld3x",
        form.current,
        "user_QGlVs4Qz8yzIHPSfomOw6"
      )
      .then(
        (result) => {
          alert("Your report has been submited.");
        },
        (error) => {}
      );
  };

  const handleLogout = () => {
    axios.get("/logout");
    auth.logout();
    navigate("/");
  };

  const handleReportSubmit = (e) => {
    e.preventDefault();
    sendIssueEmail();
    e.target.reset();
  };

  return (
    <Row className="header-banner">
      <Col className="header-col-left"></Col>
      <Col className="header-logo-container">
        <img
          className="header-logo"
          alt="Executive Access Logo"
          src={logo}
        ></img>
      </Col>
      <Col className="header-drpdwn-container">
        <div className="dropdown">
          <button className="dropbtn">
            Hello, {props.greetingName}!
            <Icon name="caret down"></Icon>
          </button>
          <div className="dropdown-content">
            <Modal
              className="report-issue-modal"
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              open={open}
              style={{
                height: "65%",
                width: "500px",
                position: "fixed",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "0px",
              }}
              trigger={
                <Button className="btn-drpdwn">
                  Report an Issue{" "}
                  <Icon
                    name="warning sign"
                    style={{ marginLeft: "5px" }}
                  ></Icon>
                </Button>
              }
            >
              <Modal.Actions>
                <Button icon="x" onClick={() => setOpen(false)} />
              </Modal.Actions>
              <Modal.Header>
                Report an Issue
                <p className="modal-header-underline"></p>
              </Modal.Header>
              <Modal.Content>
                <Modal.Description>
                  <form
                    className="report-issue-form"
                    ref={form}
                    onSubmit={handleReportSubmit}
                  >
                    <input
                      required
                      type="text"
                      placeholder="Title"
                      name="title"
                    />
                    <input
                      required
                      type="text"
                      placeholder="Name"
                      name="issuer name"
                    />
                    <textarea
                      required
                      type="text"
                      placeholder="Detailed Description of Issue"
                      name="description"
                    ></textarea>
                    <input type="submit" value="Submit Issue" />
                  </form>
                </Modal.Description>
              </Modal.Content>
            </Modal>
            <Button className="btn-drpdwn" onClick={handleLogout}>
              {" "}
              Sign Out{" "}
              <Icon name="sign out" style={{ marginLeft: "5px" }}></Icon>
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default AdminHeader;
