import React, { useState, useRef } from "react";
import { Popup } from "semantic-ui-react";

import { Col, Row } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import "./addMember.css";
import "./Storefront.css";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useNavigate } from "react-router-dom";

function RenewMemberForm(props) {
  const form = useRef();
  const [associate, setAssociate] = useState("");
  const card = "Pending";
  const acknowledged = "No";
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  //RENEW CUSTOMER SET NEW DATE
  const ChangeRenewal = (id) => {
    axiosPrivate
      .put("/changeRenewal", {
        id: id,
        card: card,
        acknowledged: acknowledged,
      })
      .then((response) => {
        props.getAllMembers();
        alert("Submition Successful");
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //SEND RENEWAL EMAIL
  const sendRenewEmail = () => {
    emailjs
      .sendForm(
        "service_640rs57",
        "template_cpec7nh",
        form.current,
        "user_QGlVs4Qz8yzIHPSfomOw6"
      )
      .then(
        (result) => {},
        (error) => {}
      );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendRenewEmail();
    ChangeRenewal(e.target.member_id.value);
    e.target.reset();
  };

  return (
    <form ref={form} onSubmit={handleSubmit}>
      <Row>
        <Col>
          <label htmlFor="associate" className="store-renew-form-label">
            Associate Name
            <span className="required-asterisk">*</span>
            <br></br>
            <span className="required-text">*REQUIRED for renewal credit.</span>
          </label>
        </Col>
        <Col>
          <input
            required
            name="associate"
            onChange={(e) => {
              setAssociate(e.target.value);
            }}
            placeholder="First and Last Name"
            className="store-renew-form-input"
          ></input>
        </Col>
      </Row>
      {/* VALUES HIDDEN SO USERS CANNOT CHANGE THEM
        THESE VALUES ARE SENT USING EMAIL.JS TO NOTIFY OFFICE THAT MEMBERSHIP HAS BEEN RENEWED.  */}
      <label
        htmlFor="member_id"
        className="store-renew-form-label"
        style={{ display: "none" }}
      >
        Member ID
      </label>
      <input
        name="member_id"
        value={props.value.id}
        className="store-renew-form-input"
        style={{ display: "none" }}
        readOnly
      ></input>
      <label
        htmlFor="customerFirst"
        className="store-renew-form-label"
        style={{ display: "none" }}
      >
        First Name
      </label>
      <input
        name="customerFirst"
        value={props.value.first_name}
        className="store-renew-form-input"
        style={{ display: "none" }}
        readOnly
      ></input>
      <label
        htmlFor="customerLast"
        className="store-renew-form-label"
        style={{ display: "none" }}
      >
        Last Name
      </label>
      <input
        name="customerLast"
        value={props.value.last_name}
        className="store-renew-form-input"
        style={{ display: "none" }}
        readOnly
      ></input>
      <label
        htmlFor="preferredStore"
        className="store-renew-form-label"
        style={{ display: "none" }}
      >
        Preferred Store
      </label>
      <input
        name="preferredStore"
        value={props.value.preferredStore}
        className="store-renew-form-input"
        style={{ display: "none" }}
        readOnly
      ></input>
      <Row>
        <Col>
          <Popup
            content="This Action Can NOT be undone."
            trigger={<input type="submit" value="Renew Customer Membership" />}
          ></Popup>
        </Col>
      </Row>
    </form>
  );
}

export default RenewMemberForm;
