import React, { useState, useRef } from "react";
import axios from "../api/axios";
import { Modal } from "semantic-ui-react";

function TempPassForm() {
  const [openTwo, setOpenTwo] = React.useState(false);
  const form = useRef();

  const [formState, setFormState] = useState({
    email: "",
    phone: "",
  });

  //call temp password sending function on server side
  const sendPasswordReset = () => {
    axios
      .post("/sendTempPass", {
        email: formState.email,
        phone: formState.phone,
      })
      .then((response) => {
        if (response.data.affectedRows) {
          setOpenTwo(false);
          alert("Password sent to " + formState.email);
        } else {
          alert("Email and Phone number do not match any account on file");
        }
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setFormState({
      ...formState,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendPasswordReset();
    e.target.reset();
  };

  return (
    <Modal
      onClose={() => setOpenTwo(false)}
      onOpen={() => setOpenTwo(true)}
      open={openTwo}
      trigger={<button className="forgot-btn"> Forgot Password? </button>}
      style={{
        height: "300px",
        width: "300px",
        position: "fixed",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        borderRadius: "0px",
      }}
    >
      <Modal.Content>
        <form ref={form} onSubmit={handleSubmit}>
          <p className="modal-title-pswd"> Receive Temporary Password </p>
          <p className="login-underline"></p>
          <input
            className="loginInput"
            required
            name="email"
            type="email"
            placeholder="Email"
            onChange={handleChange}
          ></input>
          <br></br>
          <input
            className="loginInput"
            required
            name="phone"
            type="tel"
            pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
            placeholder="Phone Number"
            onChange={handleChange}
          ></input>
          <p
            className="required-text"
            style={{
              textAlign: "center",
              fontSize: "12px",
              marginBottom: "0px",
            }}
          >
            {" "}
            *Phone # Format: 1234567890{" "}
          </p>
          <br></br>
          <input type="submit" className="pswd-validate" value="Send"></input>
          <input
            value={formState.email}
            type="text"
            placeholder="email"
            name="customerEmail"
            readOnly
            style={{ display: "none" }}
          ></input>
        </form>
      </Modal.Content>
    </Modal>
  );
}

export default TempPassForm;
