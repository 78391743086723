import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Card } from "semantic-ui-react";

import { Container, Row, Col } from "react-bootstrap";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import "./Profilepage.css";
import ProfileHeader from "./ProfileHeader.js";
import SpecialRequestForm from "./SpecialRequestForm.js";
import { useNavigate } from "react-router-dom";

function RequestListItem(props) {
  let update = new Date(props.value.date_updated)
    .toUTCString()
    .split(" ")
    .slice(0, 4)
    .join(" ");

  return (
    <div className="request-item-row">
      <Row className="message-underline"></Row>
      <Row>
        <Col>
          <p className="request-style">{props.value.brand}</p>
        </Col>
        <Col>
          <p className="request-style">{props.value.item}</p>
        </Col>
        <Col className="request-col-hide-sm">
          <p className="request-style">{props.value.sku}</p>
        </Col>
        <Col>
          <p className="request-style"> {props.value.status}</p>
        </Col>
        <Col className="request-col-hide-md">
          <p className="request-style">{update}</p>
        </Col>
      </Row>
    </div>
  );
}

function MarketItem(props) {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  // REQUEST ITEMS MARKET
  const AddMarketItemToRequests = (id) => {
    axiosPrivate
      .post("/marketCart", {
        custName: props.custName,
        memberId: props.memberId,
        id: id,
      })
      .then(() => {
        props.getMemberRequests();
        alert("Your request has been logged.");
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };
  let formatCurrency = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
  });

  return (
    <div className="card-catalog-container">
      <img src={props.value.imageURL} className="item-img" alt="Product"></img>
      <Card.Content style={{ marginLeft: "5%", marginRight: "5%" }}>
        <Card.Header className="item-details" style={{ fontSize: "20px" }}>
          {props.value.Make}
        </Card.Header>
        <Card.Meta className="item-details">{props.value.Category}</Card.Meta>
        <Card.Meta className="item-details">{props.value.Model}</Card.Meta>
        <Card.Description className="item-details" style={{ color: "#dc1b29" }}>
          <span className="date">
            {formatCurrency.format(props.value.Price)}
          </span>
        </Card.Description>
        <Card.Description className="item-details">
          {props.value.Sku}
        </Card.Description>
      </Card.Content>
      <Row>
        <Col>
          <p className="item-details"> AVAILABLE: {props.value.Quantity}</p>
        </Col>
      </Row>
      <Row>
        <div>
          <button
            className="request-item-btn"
            onClick={() => {
              AddMarketItemToRequests(props.value.id);
            }}
          >
            Request Item
          </button>
        </div>
      </Row>
    </div>
  );
}

function Profile() {
  const [memberDetails, setMemberDetails] = useState("");
  const [memberId, setMemberId] = useState("");
  const [requestList, setRequestList] = useState("");
  const [custName, setCustName] = useState("");
  const [catalog, setCatalog] = useState("");
  const { id } = useParams();
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  //GET CATALOG
  const GetCatalog = () => {
    axiosPrivate
      .get("/getCatalogNow")
      .then((response) => {
        setCatalog(response.data);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const getMemberInfo = () => {
    axiosPrivate
      .get("/getSingleMem", {
        params: { id: id },
      })
      .then((response) => {
        const result = response.data;
        setMemberDetails(result[0]);
        setCustName(result[0].first_name + " " + result[0].last_name + " " + result[0].Suffix);
        setMemberId(result[0].id);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  //GETTING SPECIFIC MEMBER REQUESTS
  const getMemberRequests = () => {
    axiosPrivate
      .get("/getMemberRequests", {
        params: { id: id },
      })
      .then((response) => {
        const result = response.data;
        setRequestList(result);
      })
      .catch((error) => {
        console.error(error);
        navigate("/");
      });
  };

  const specialRequestList = Object.keys(requestList)
    .filter((req) => {
      if (requestList[req].status !== "CANCELED") {
        return req;
      }
      return null;
    })
    .map((request) => (
      <RequestListItem key={request} value={requestList[request]} />
    ));

  const marketList = Object.keys(catalog).map((item) => (
    <MarketItem
      key={item}
      value={catalog[item]}
      getMemberRequests={getMemberRequests}
      memberId={memberId}
      custName={custName}
    />
  ));

  useEffect(() => {
    //Load Profile Data
    getMemberInfo();
    getMemberRequests();
    GetCatalog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="profileMain">
      <Container fluid="md">
        <ProfileHeader
          loggedInMember={memberDetails}
          getMemberInfo={getMemberInfo}
        />
        <Row className="section-background">
          <Col>
            <div style={{ height: "50px" }}></div>
            <h3 className="section-titles">PROFILE INFORMATION</h3>
            <p className="underline"></p>
            <div className="personal-info-container">
              <h2 className="customer-name">
                {memberDetails.first_name} {memberDetails.middle_name}{" "}
                {memberDetails.last_name}{" "} {memberDetails.Suffix}
              </h2>
              <Row>
                <Col className="personal-info-label-col">
                  <p>MEMBER ID:</p>
                </Col>
                <Col className="personal-info-col">
                  <p>{memberDetails.number}</p>
                </Col>
              </Row>
              <Row>
                <Col className="personal-info-label-col">
                  <p>PREFERRED STORE:</p>
                </Col>
                <Col className="personal-info-col">
                  <p>{memberDetails.preferredStore}</p>
                </Col>
              </Row>
              <Row>
                <Col className="personal-info-label-col">
                  <p>USERNAME:</p>
                </Col>
                <Col className="personal-info-col">
                  <p>{memberDetails.userName}</p>
                </Col>
              </Row>
              <Row>
                <Col className="personal-info-label-col">
                  <p>EMAIL:</p>
                </Col>
                <Col className="personal-info-col">
                  <p>{memberDetails.email}</p>
                </Col>
              </Row>
              <Row>
                <Col className="personal-info-label-col">
                  <p>PHONE #:</p>
                </Col>
                <Col className="personal-info-col">
                  <p>{memberDetails.phone}</p>
                </Col>
              </Row>
              <Row>
                <Col className="personal-info-label-col">
                  <p>ADDRESS:</p>
                </Col>
                <Col className="personal-info-col">
                  <p>{memberDetails.address}</p>
                </Col>
              </Row>
            </div>
            <p className="profile-info-underline"></p>
          </Col>
        </Row>
        <Row className="section-background">
          <Col>
            <div style={{ height: "50px" }}></div>
            <h3 className="section-titles requests-title">
              PENDING SPECIAL REQUESTS
            </h3>
            <p className="underline"></p>
            <div className="request-container">
              <div className="request-scroll">
                <Row>
                  <Col>
                    <p className="request-style-header">Make</p>
                  </Col>
                  <Col>
                    <p className="request-style-header">Model</p>
                  </Col>
                  <Col className="request-col-hide-sm">
                    <p className="request-style-header">SKU</p>
                  </Col>
                  <Col>
                    <p className="request-style-header">Status</p>
                  </Col>
                  <Col className="request-col-hide-md">
                    <p className="request-style-header">Modified</p>
                  </Col>
                </Row>
                {specialRequestList}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="section-background">
          <Col>
            <div style={{ height: "50px" }}></div>
            <h3 className="section-titles messages-title">MAKE A REQUEST</h3>
            <p className="underline"></p>
            <SpecialRequestForm
              loggedInMember={memberDetails}
              getMemberRequests={getMemberRequests}
            />
          </Col>
        </Row>

        <Row className="section-background">
          {" "}
          <Col>
            <div style={{ height: "50px" }}></div>
            <h3 className="section-titles">Special Member Items</h3>
            <p className="underline"></p>
            <div className="market-items-container">{marketList}</div>
          </Col>
          <div style={{ height: "50px" }}></div>
        </Row>
      </Container>
    </div>
  );
}

export default Profile;
