import Axios from "axios";

const BASE_URL = "https://executive-app.herokuapp.com";
const LOCAL_URL = "http://localhost:3001";

export default Axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

export const axiosPrivate = Axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
