import React from "react";
import "./LoginTextTop.css";

function LoginTextTop() {
  return (
    <div className="LoginContainer">
      <div className="titleDiv">
        {" "}
        <h1>
          AN <span className="login-title-emphasis">EXCLUSIVE</span> BENEFITS
          PROGRAM FOR
          <br />
          FIREARMS ENTHUSIASTS
        </h1>
      </div>

      <p className="underline"></p>
    </div>
  );
}

export default LoginTextTop;
