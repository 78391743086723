import "./LoginHeader.css";
import Banner from "../images/ExecutiveAccessClub_Web.png";
import React from "react";
import { Col, Row } from "react-bootstrap";

function LoginHeader() {
  return (
    <div>
      <Row className="g-0">
        <Col></Col>
        <Col>
          <img id="loginLogo" alt="EAC" src={Banner}></img>
        </Col>
        <Col></Col>
      </Row>
    </div>
  );
}

export default LoginHeader;
